import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NormalizedDataElement } from '../models/NormalizedDataElement';
import { v4 as uuidv4 } from 'uuid';
import { iNormalizedDataDTO } from '../../../../s3qr-DNADTO/src/dto/NormalizedDataDTO';
import { iNormalizedDataElementDTO } from '../../../../s3qr-DNADTO/src/dto/NormalizedDataElementDTO';
@Component({
  selector: 'app-addNormalizedDataForm',
  templateUrl: './addNormalizedDataForm.component.html',
  styleUrls: ['./addNormalizedDataForm.component.scss']
})
export class AddNormalizedDataFormComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNormalizedDataFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NormalizedDataElement) {


  }

  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      dye_Peak: ['', Validators.required],
      area: ['', Validators.required],
      size: ['', Validators.required],
      height: ['', Validators.required]
    });
  }
  get f() { return this.registerForm.controls; }


  addNormalized() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      const exit: iNormalizedDataElementDTO = {
        id: uuidv4(),
        dye_Peak: this.data.dye_Peak,
        sample_file_Name: '',
        marker: '',
        allele: '',
        size: this.data.size+"",
        height: this.data.height + "",
        area: this.data.area + "",
        data_Point: '',
        n_height: +this.data.height,
        n_size: +this.data.size
      }


      console.log(this.data)


      this.dialogRef.close(exit);
    }


  }

}
