import { RawDataElement } from "./RawDataElement";

export class RawGraph {
  filename: string;

  rawDataElements: RawDataElement[] = [];

  constructor(_filename: string, _rawDataElements: RawDataElement[]) {
    this.filename = _filename;
    this.rawDataElements = _rawDataElements;
  }





}
