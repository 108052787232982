<app-generic_report_update_form
  [labid]="this.token.user.lab"
  *ngIf="showeditor"
>
</app-generic_report_update_form>
<mat-card class="distanza">
  <mat-card-title>COMPLETED LOTS LIST : </mat-card-title>

  <mat-card-content
    class="cella example-card mat-elevation-z6"
    *ngFor="let lot of lots"
  >
    <div class="row">
      <div class="column_sx">
        <i>ID : </i> {{ lot._id }} <br />
        <i>Lot :</i> {{ lot.lot }} <br />
        <i>Category :</i> {{ lot.category }} <br />
      </div>
      <div class="column_center">
        <i>Manufacturer :</i> {{ lot.manufacturer.basic_info.name }} <br />
        <i>Creation Date :</i> {{ lot.creation_date | date: "yyyy-MM-dd" }}
        <br />
        <i>Expiration Date :</i> {{ lot.expiration_date | date: "yyyy-MM-dd" }}
        <br />
      </div>
      <div class="column_dx">
        <div class="row">
          <div class="column_half">
            <button
              mat-stroked-button
              color="warn"
              class="distanza5"
              (click)="addAnalysis(lot)"
            >
              Edit Analysis
            </button>
          </div>
          <div class="column_half">
            <button
              *ngIf="lot.image"
              mat-stroked-button
              color="warn"
              class="distanza4"
              (click)="showimage(lot.image)"
            >
              Image
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column_half">
            <button
              *ngIf="lot.desc"
              mat-stroked-button
              color="warn"
              class="distanza4"
              (click)="showdesc(lot.desc)"
            >
              Description
            </button>
          </div>
          <div class="column_half">
            <button

              mat-stroked-button
              color="warn"
              matTooltip="Delete"
              class="distanza4"
              (click)="deletelot(lot)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
