import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { iAnalysisInDTO } from 's3qr-dnadto/src/dto/AnalysisDTO';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvestigationService {


  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }



  public async investigate(toanalys : iAnalysisInDTO): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.post(environment.main_url + environment.backend_dna_doAnalysis,toanalys).toPromise();

    //
  }


}
