import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Logger, LogLevel } from 'ask-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { CategoryResult, InvestigationResult, SingleResult } from '../models/InvestigationResult';
import { IntecomService } from '../providers/intecom.service';
const LOGGER = Logger.getLogger('InvestigationResultsDetailsComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-InvestigationResultsDetails',
  templateUrl: './InvestigationResultsDetails.component.html',
  styleUrls: ['./InvestigationResultsDetails.component.css']
})
export class InvestigationResultsDetailsComponent implements OnInit {

  cat_results: CategoryResult[] = []
  gini: number = 0;
  displayedColumns: string[] = ['id', 'code', 'distance', 'sim'];


  subscription: Subscription;

  constructor(private intercom: IntecomService, private _snackBar: MatSnackBar) {

  }
  setBackground(i) {
    return i==0 ? "distanza_winner" : i % 2 > 0 ? "distanza" : "distanza_2";
  }
  setBackgroundTable(i) {
    return i==0? "table_winner" : i % 2 > 0 ? "table2" : "table_2";
  }
  ngOnInit() {

    this.subscription = this.intercom.investigationResultArrived$.subscribe((result: InvestigationResult) => {
      if (result) {
        this.gini = result.gini
        LOGGER.info(result)

        this.cat_results = Object.assign([], result.categories);
        this.cat_results.sort((a, b) => (a.prob > b.prob) ? -1 : 1)
    }

    });
}

ngOnDestroy(): void {
  this.subscription.unsubscribe();

}

}
