

<table mat-table [dataSource]="normalizedData "   matSort class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

<!-- Position Column -->

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code </th>
    <td mat-cell *matCellDef="let element"> {{element.rawdata ? element.rawdata.code : ""}} </td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Title">Title </th>
    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
  </ng-container>
    <!-- Name Column -->
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Category">Category </th>
    <td mat-cell *matCellDef="let element"> {{element.category}} </td>
  </ng-container>
  <ng-container matColumnDef="filterH">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FilterH" >FilterH </th>
    <td mat-cell *matCellDef="let element"> {{element.filterH}} </td>
  </ng-container>

  <ng-container matColumnDef="filterS" >
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by FilterS">FilterS </th>
    <td mat-cell *matCellDef="let element"> {{element.filterS}} </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Author">Author </th>
    <td mat-cell *matCellDef="let element"> {{element.author}} </td>
  </ng-container>



  <!-- Remove Column -->
  <ng-container matColumnDef="desc">
    <th mat-header-cell *matHeaderCellDef> Desc </th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="show(element)" color="primary" aria-label="Desc Element">
        <mat-icon>info</mat-icon></button>

    </td>
</ng-container>
  <!-- Add Column -->
  <ng-container matColumnDef="graph">
    <th mat-header-cell *matHeaderCellDef> Chart </th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="graph(element)" color="warn" aria-label="Add Element">
        <mat-icon> bar_chart</mat-icon></button>

    </td>
  </ng-container>
  <!-- Add Column -->
  <ng-container matColumnDef="add">
    <th mat-header-cell *matHeaderCellDef> Add </th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="add(element)" color="warn" aria-label="Add Element">
        <mat-icon>add</mat-icon></button>

    </td>
  </ng-container>
  <!-- Add Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef> Select </th>
    <td mat-cell *matCellDef="let element">
           <mat-radio-button (click)="$event.stopPropagation()"
                                      (change)="select(element)"
                                        name="A"
                                      ></mat-radio-button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


</table>
  <mat-paginator [pageSizeOptions]="[10, 20, 40]"
                 showFirstLastButtons
                 >
