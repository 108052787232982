import { Component, OnInit } from '@angular/core';
import { Logger, LogLevel } from 'ask-logger';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { IntecomService } from '../providers/intecom.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
const LOGGER = Logger.getLogger('NormalizedDataFinderComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-normalizedDataFinder',
  templateUrl: './normalizedDataFinder.component.html',
  styleUrls: ['./normalizedDataFinder.component.scss']
})
export class NormalizedDataFinderComponent implements OnInit {

  myControl = new FormControl();
  data: iNormalizedDataLISTDTO[] = [];
  options: string[] = [];
  alloptions: string[] = [];
  selected: string;
  _showgraph: boolean = false;
  filteredOptions: Observable<string[]>;



  constructor(private nDataService: NormalizedDataService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }


  ngOnInit() {
    this.nDataService.getNormalizedData().then((response : iNormalizedDataLISTDTO []) => {

      if (response) {
        this.data = response;
        this.data.forEach(x => {
          const raw = x.rawdata ? x.rawdata.code : "";

          this.alloptions.push(x.id + "#" + x.author + " " + x.desc + " " + raw  + " " + x.category + " " + x.title)
        });
      }
    }).catch((error) => {
      this._snackBar.open("Error retrinving data", "Ok");
      LOGGER.info(error)
    })



  }

  change(event) {
    LOGGER.info("valore da filtrare ->" + event)
    const filterValue = event.toLowerCase();
    this.options = this.alloptions.filter(x => x.toLowerCase().includes(filterValue) )

  }


  go() {
    LOGGER.info(this.options)
    const toSend: string[] = [];
    if (this.options) {
      this.options.forEach(x => {
        if (x.split("#").length > 0) {
          toSend.push(x.split("#")[0]);
        }
      })
    }
    LOGGER.info(toSend)
    this.intercom.refreshNormalizedDataToShow(toSend)
  }

  newDb() {
    this.intercom.newDatabase(true);

  }

}
