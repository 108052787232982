import { iRawDataElementDTO } from 's3qr-dnadto/src/dto/RawDataElementDTO';
export class RawDataElement implements iRawDataElementDTO  {
  id: string;
  dye_Peak : string;
  sample_file_Name: string;
  marker: string;
  allele: string;
  size: string;
  height: string;
  area: string;
  data_Point: string;



	constructor($dye_Peak: string, $sample_file_Name: string, $marker: string, $allele: string, $size: string, $height: string, $area: string, $data_Point: string) {
		this.dye_Peak = $dye_Peak;
		this.sample_file_Name = $sample_file_Name;
		this.marker = $marker;
		this.allele = $allele;
		this.size = $size;
		this.height = $height;
		this.area = $area;
		this.data_Point = $data_Point;
	}



    /**
     * Getter $dye_Peak
     * @return {string}
     */
	public get $dye_Peak(): string {
		return this.dye_Peak;
	}

    /**
     * Getter $sample_file_Name
     * @return {string}
     */
	public get $sample_file_Name(): string {
		return this.sample_file_Name;
	}

    /**
     * Getter $marker
     * @return {string}
     */
	public get $marker(): string {
		return this.marker;
	}

    /**
     * Getter $allele
     * @return {string}
     */
	public get $allele(): string {
		return this.allele;
	}

    /**
     * Getter $size
     * @return {string}
     */
	public get $size(): string {
		return this.size;
	}

    /**
     * Getter $height
     * @return {string}
     */
	public get $height(): string {
		return this.height;
	}

    /**
     * Getter $area
     * @return {string}
     */
	public get $area(): string {
		return this.area;
	}

    /**
     * Getter $data_Point
     * @return {string}
     */
	public get $data_Point(): string {
		return this.data_Point;
	}

    /**
     * Setter $dye_Peak
     * @param {string} value
     */
	public set $dye_Peak(value: string) {
		this.dye_Peak = value;
	}

    /**
     * Setter $sample_file_Name
     * @param {string} value
     */
	public set $sample_file_Name(value: string) {
		this.sample_file_Name = value;
	}

    /**
     * Setter $marker
     * @param {string} value
     */
	public set $marker(value: string) {
		this.marker = value;
	}

    /**
     * Setter $allele
     * @param {string} value
     */
	public set $allele(value: string) {
		this.allele = value;
	}

    /**
     * Setter $size
     * @param {string} value
     */
	public set $size(value: string) {
		this.size = value;
	}

    /**
     * Setter $height
     * @param {string} value
     */
	public set $height(value: string) {
		this.height = value;
	}

    /**
     * Setter $area
     * @param {string} value
     */
	public set $area(value: string) {
		this.area = value;
	}

    /**
     * Setter $data_Point
     * @param {string} value
     */
	public set $data_Point(value: string) {
		this.data_Point = value;
	}



}


