import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import * as papa from "papaparse";
import { iRawDataElementDTO } from 's3qr-dnadto/src/dto/RawDataElementDTO';
import { DnagraphComponent } from '../dnagraph/dnagraph.component';
import { RawDataElement } from '../models/RawDataElement';
import { ViewChild } from '@angular/core';
import { Generic_ReportService } from '../providers/generic_Report.service';
import { RawDataFormComponent } from '../rawDataForm/rawDataForm.component';
import { ImportList } from '../models/ImportKey';
import { RawDataGraphShowComponent } from '../raw-data-graph-show/raw-data-graph-show.component';
import { RawGraph } from '../models/RawGraph';
import { SelectionModel } from '@angular/cdk/collections';
import { Logger, LogLevel } from 'ask-logger';
import { RawDataBulkFormComponent } from '../rawDataBulkForm/rawDataBulkForm.component';
import { MatSnackBar } from '@angular/material/snack-bar';
const LOGGER = Logger.getLogger('RawDataListComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-analysis-g',
  templateUrl: './analysis-g.component.html',
  styleUrls: ['./analysis-g.component.css']
})
export class AnalysisGComponent implements OnInit {

  @ViewChild('filesdna')
  myInputVariable: ElementRef;

  displayedColumns: string[] = [];
  data = new Map<String, ImportList>();
  keys: string[] = [];

  _show_first_result: boolean = true;
  selection = new SelectionModel<ImportList>(true, []);


  constructor(public dialog: MatDialog, public h: Generic_ReportService, private _snackBar: MatSnackBar) {

    this.displayedColumns = ['name', 'author', 'import', 'graph', 'select'];
  }



  ngOnInit(): void {

    this.h.getReports().then(aaa => {
      console.log("-------------GET")
      console.log(aaa)
    })
    this.h.postReports().then(aaa => {
      console.log("-------------POST")
      console.log(aaa)
    })
  }

  getFile($event) {

    this.data = new Map<String, ImportList>();
    let file: File = $event.target.files[0];

    papa.parse(file, {
      delimiter: "\t",
      header: true,
      dynamicTyping: true,

      step: (row) => {

        if (row.data["Sample File Name"]) {
          let temp: ImportList = new ImportList(row.data["Sample File Name"]);
          if (this.data.has(row.data["Sample File Name"])) {
            temp = this.data.get(row.data["Sample File Name"]);
          }
          let element = new RawDataElement(row.data["Dye/Sample Peak"],
            row.data["Sample File Name"],
            row.data["Marker"],
            row.data["Allele"],
            row.data["Size"],
            row.data["Height"],
            row.data["Area"],
            row.data["Data Point"]
          )

          // console.log("->", element)
          temp.addRawDataElement(element);
          this.data.set(row.data["Sample File Name"], temp);

          // row.data["Dye/Sample Peak"])
        }
      },
      complete: () => {

        this.keys = Object.keys(this.data.keys());
        this._show_first_result = false;
        // for(let key of this.data.keys()) {
        //   console.log(key, " -> ", this.data.get(key).length);                   //Lokesh Raj John
        // }

      }
    });

  }

  clear() {
    this.data = new Map<String, ImportList>();
    this._show_first_result = true;
    this.myInputVariable.nativeElement.value = "";
  }

  saveRaw(key: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "80%";
    dialogConfig.minHeight = "80%";
    dialogConfig.data = this.data.get(key).rawDataElements;
    const dialogRef2 = this.dialog.open(RawDataFormComponent, dialogConfig);
     dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.data.get(key).category = result;
      } else {

      }


    });
  }


  showgraph(key: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "80%";
    dialogConfig.minHeight = "80%";
    let toSend: RawGraph = new RawGraph(key, this.data.get(key).rawDataElements)
    dialogConfig.data = toSend;
    const dialogRef2 = this.dialog.open(RawDataGraphShowComponent, dialogConfig);
    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.data.get(key).category = result;
      } else {

      }


    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRows = this.data.size

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {

      this.selection.clear()
    } else {

      for (let [key, value] of this.data) {

        this.selection.select(value);
      }
    }



  }

  bulkImport() {
    if (this.selection.hasValue()) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = "80%";
      dialogConfig.minHeight = "80%";
      dialogConfig.data = this.selection.selected;
      const dialogRef2 = this.dialog.open(RawDataBulkFormComponent, dialogConfig);
      dialogRef2.afterClosed().subscribe( (result: ImportList[]) => {
        console.log('The dialog was closed');
        if (result) {
          result.forEach(x => {
            this.data.get(x.filename).category = x.category

          })
        }
      });
    } else {
      this._snackBar.open("Please Select at least a row", "Ok");
    }
  }


}
