import { Component, Inject, OnInit } from '@angular/core';
import { inDNADatabaseDTO } from 's3qr-dnadto/src/dto/DNADatabaseDTO';
import { KcConnectService } from '../providers/kc-connect.service';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { DatabaseService } from '../providers/Database.service';
import { Logger, LogLevel } from 'ask-logger';
import { outDNADatabaseDTO } from '../../../../s3qr-DNADTO/src/dto/DNADatabaseDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { logged } from '../support/logged';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { IdentityProviderService } from '../providers/identityProvider.service';
import { MyWebToken } from '../models/webtoken';
const LOGGER = Logger.getLogger('DatabaseSaveFormComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-databaseSaveForm',
  templateUrl: './databaseSaveForm.component.html',
  styleUrls: ['./databaseSaveForm.component.scss']
})
export class DatabaseSaveFormComponent  implements OnInit {

  registerForm: FormGroup;
  database: inDNADatabaseDTO;
  normalizedDatas: iNormalizedDataLISTDTO[];


  constructor(protected keycloakService: KeycloakService,
    private identity: IdentityProviderService,
    private databaseService: DatabaseService,
    public dialogRef: MatDialogRef<DatabaseSaveFormComponent>, private _snackBar: MatSnackBar,
    protected comunication: KcConnectService,
    @Inject(MAT_DIALOG_DATA) public data: iNormalizedDataLISTDTO[]) {


    this.normalizedDatas = this.data;
    LOGGER.info("Arrivati: ", this.normalizedDatas)
  }

  ngOnInit() {
    this.identity.getUser().then((result: string) => {
      LOGGER.info(result)
      let token: MyWebToken = MyWebToken.of(result);
      this.database = this.database = {
        id: '',
        name: '',
        desc: '',
        author: token.user.name
      }
    })

  }
  saveDatabase() {
    LOGGER.info(this.database);
    this.databaseService.postDatabase(this.database).then((reult: outDNADatabaseDTO) => {
      LOGGER.info(reult)
      this.databaseService.postNormalizedData(reult.id,this.normalizedDatas).then(result2 => {
        LOGGER.info("RESULT 2 ", result2)
        this.dialogRef.close(result2);
      }).catch(e => {
        LOGGER.error(e)
        this.dialogRef.close(false);
      })
    }).catch(e => {
      this._snackBar.open(e, "Ok");
      LOGGER.error(e)
      this.dialogRef.close(false);
    })

  }
  close() {

    this.dialogRef.close(false);
  }
}

