import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MyWebToken } from '../models/webtoken';
import * as papa from "papaparse";
import * as d3 from 'd3-selection';
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import { Platform } from '@angular/cdk/platform';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('DnagraphformComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-dnagraphform',
  templateUrl: './dnagraphform.component.html',
  styleUrls: ['./dnagraphform.component.css']
})
export class DnagraphformComponent implements OnInit {
  @Input()
  token: MyWebToken;


  @Input()
  points: any;


  filtro_di_tipo: number = 115;

  /// those are for the d3grap
  c3chart: any;
  width: number;
  height: number;
  margin: any;
  x: any;
  y: any;
  svg: any;
  g: any;
  fontsize: string;
  isThereAGraph: boolean = false;
  // @ViewChild("lineCanvas") lineCanvas: ElementRef;



  constructor(private intercom: IntecomService ) {
    if (window.innerWidth < 600) {
      this.fontsize = "25px";
      this.margin = { top: 10, right: 10, bottom: 60, left: 90 };
    } else {
      this.fontsize = "10px";
      this.margin = { top: 10, right: 10, bottom: 40, left: 40 };
    }
    this.width = 900 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;

  }

  ngOnInit() {

    if (this.points) {


      this.initSvg();
      this.initAxis_fromPoints();
      this.drawAxis();
      this.drawBars(this.points);
    }
  }

  clearGraph($event) {
    this.points = [];
    this.isThereAGraph = false;
    this.svg.remove();
    this.intercom.dnaDataForm(null);
  }

  getFileGrafico($event) {

    LOGGER.info("[getFileGrafico] filter : ", this.filtro_di_tipo)
    let biggestX: number = 0;
    let x: number[] = [];
    let y: number[] = [];
    let labelX: number[] = [];
    let temp_points = new Map();
    let points: any[] = [];

    let file: File = $event.target.files[0];

    papa.parse(file, {
      delimiter: "\t",
      header: true,
      dynamicTyping: true,

      step: (row) => {
        LOGGER.info("[getFileGrafico] Row:", row.data);
        if (+row.data.Size) {
          if (this.filtro_di_tipo < +row.data.Size) {
            if ((+row.data.Size) > biggestX) {
              biggestX = +row.data.Size;
            }

            x.push(+row.data.Size);
            y.push(+row.data.Height);

            LOGGER.info("[getFileGrafico] X :", +row.data.Size);
            LOGGER.info("[getFileGrafico] Y :", +row.data.Height);

            temp_points.set(Math.floor(+row.data.Size), { x: +row.data.Size, y: +row.data.Height });


          }

        }
      },
      complete: () => {
        LOGGER.info("[getFileGrafico]  All done! ", temp_points);
        LOGGER.info("[getFileGrafico] points",points);




        if (temp_points.size > 0) {

          let i: number = 0;
          temp_points.forEach((value: any, key: string) => {
            let v: number = +value.y;

            points[i] = value;
            labelX[i] = value.x;
            i++;

          });

          this.initSvg()
          this.initAxis(x, y);
          this.drawAxis();
          this.drawBars(points);
          this.isThereAGraph = true;
          this.intercom.dnaDataForm(points);


        }
      }
    });

  }


  initSvg() {

    if (!this.isThereAGraph) {
      d3.selectAll("g > *").remove()

      if (!this.svg) {
          this.svg = d3.select("#barChart3")
        .append("svg")
        .attr("width", '100%')
        .attr("height", '100%')
        .attr('viewBox', '0 0 900 500');
      }

      this.g = this.svg.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
      this.isThereAGraph = false;
    } else {
      d3.selectAll("g > *").remove()
      this.g = this.svg.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    }
  }
  initAxis(x: number[], y: number[]) {
    // this.x = d3Scale.scaleBand().rangeRound([0, this.width]).padding(0.1);
    this.x = d3Scale.scaleLinear().domain([0, d3Array.max(x)]).range([0, this.width]);
    this.y = d3Scale.scaleLinear().domain([d3Array.max(y), 0]).range([0, this.height]);
    // this.y = d3Scale.scaleLinear().rangeRound([this.height, 0]);
    // this.x.domain(y);
    // this.y.domain([0, d3Array.max(y)]);
  }

  initAxis_fromPoints() {
    let x1: number[] = [];
    let y1: number[] = [];

    for (let i = 0; i < this.points.length; i++) {
      x1.push(+this.points[i].x);
      y1.push(+this.points[i].y);
    }

    this.x = d3Scale
      .scaleLinear()
      .domain([0, d3Array.max(x1)])
      .range([0, this.width]);
    this.y = d3Scale
      .scaleLinear()
      .domain([d3Array.max(y1), 0])
      .range([0, this.height]);


  }

  drawAxis() {

    this.g.append("g")
      .attr("class", "axis axis--x")
      .style("font-size", this.fontsize)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3Axis.axisBottom(this.x));
    this.g.append("g")
      .style("font-size", this.fontsize)
      .attr("class", "axis axis--y")
      .call(d3Axis.axisLeft(this.y))
      .append("text")
      .attr("class", "axis-title")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("Frequency");
  }

  drawBars(points: any[]) {
    this.g.selectAll(".bar")
      .data(points)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d) =>
        this.x(d.x)
      )
      .attr("y", (d) => this.y(d.y))
      .attr("width", (d) => "1")
      .attr("height", (d) => this.height - this.y(d.y));
  }

}
