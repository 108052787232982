import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { Logger, LogLevel } from 'ask-logger';
import { RawDataElement } from '../models/RawDataElement';
import { RawGraph } from '../models/RawGraph';
const LOGGER = Logger.getLogger('NormalizedGraphShowComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-raw-data-graph-show',
  templateUrl: './raw-data-graph-show.component.html',
  styleUrls: ['./raw-data-graph-show.component.css']
})
export class RawDataGraphShowComponent implements OnInit {

  c3chart: any;
  fontsize: string;
  margin: any;
  width: number;
  height: number;
  g: any;
  svg: any;
  x: any;
  y: any;
  normalizedData: RawDataElement[]
  points: any[] = []
  maxS: number = 0;
  maxH: number = 0;
  title: string = "";

  constructor(
    public dialogRef: MatDialogRef<RawDataGraphShowComponent>,

    @Inject(MAT_DIALOG_DATA) public data: RawGraph
  ) {



    if (window.innerWidth < 600) {
      this.fontsize = "25px";
      this.margin = { top: 10, right: 10, bottom: 60, left: 90 };
    } else {
      this.fontsize = "10px";
      this.margin = { top: 10, right: 10, bottom: 40, left: 40 };
    }
    this.width = 900 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;




  }

  ngOnInit() {
    this.title = this.data.filename
    this.data.rawDataElements.forEach(x => {
      if (+x.size > this.maxS) this.maxS = +x.size;
      if (+x.height> this.maxH) this.maxH = +x.height;
      this.points.push({ x: +x.$size, y: x.$height })
    })
    this.initSvg();
    this.initAxis_fromPoints();
    this.drawAxis();
    this.drawBars(this.points);

  }

  initSvg() {

    // if (!this.isThereAGraph) {
    d3.selectAll("g > *").remove()

    if (!this.svg) {
      this.svg = d3.select("#barChart3")
        .append("svg")
        .attr("width", '100%')
        .attr("height", '100%')
        .attr('viewBox', '0 0 980 500');
    }

    this.g = this.svg.append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    // this.isThereAGraph = true;
    // } else {
    //   d3.selectAll("g > *").remove()
    //   this.g = this.svg.append("g")
    //     .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    // }



  }
  initAxis(x: number[], y: number[]) {
    // this.x = d3Scale.scaleBand().rangeRound([0, this.width]).padding(0.1);
    this.x = d3Scale.scaleLinear().domain([0, d3Array.max(x)]).range([0, this.width]);
    this.y = d3Scale.scaleLinear().domain([d3Array.max(y), 0]).range([0, this.height]);
    // this.y = d3Scale.scaleLinear().rangeRound([this.height, 0]);
    // this.x.domain(y);
    // this.y.domain([0, d3Array.max(y)]);
  }

  initAxis_fromPoints() {
    let x1: number[] = [];
    let y1: number[] = [];
    console.log("Ci entro: ", this.points)
    // this.normalizedData.points.forEach(point => {
    //   console.log(point)
    // })

    for (let i = 0; i < this.points.length; i++) {
      x1.push(+this.points[i].x);
      y1.push(+this.points[i].y);

    }
    // console.log("aaa : " + JSON.stringify(x1));
    this.x = d3Scale
      .scaleLinear()
      .domain([0, this.maxS])
      .range([0, this.width]);
    this.y = d3Scale
      .scaleLinear()
      .domain([this.maxH, 0])
      .range([0, this.height]);

    // this.x = d3Scale
    //   .scaleLinear()
    //   .domain([0, 500])
    //   .range([0, 500]);
    // this.y = d3Scale
    //   .scaleLinear()
    //   .domain([100, 0])
    //   .range([0, 100]);

    // console.log("------x-------------------------");
    // console.log(this.x);
    // console.log("-------------------------------------");
  }

  drawAxis() {

    this.g.append("g")
      .attr("class", "axis axis--x")
      .style("font-size", this.fontsize)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3Axis.axisBottom(this.x));
    this.g.append("g")
      .style("font-size", this.fontsize)
      .attr("class", "axis axis--y")
      .call(d3Axis.axisLeft(this.y))
      .append("text")
      .attr("class", "axis-title")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("Frequency");
  }

  drawBars(points: any[]) {
    this.g.selectAll(".bar")
      .data(points)
      .enter().append("rect")
      .style("fill", "green")
      .attr("class", "bar")
      .attr("x", (d) =>
        this.x(d.x)
      )
      .attr("y", (d) => this.y(d.y))
      .attr("width", (d) => "1")
      .attr("height", (d) => this.height - this.y(d.y));
  }
}
