import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntecomService } from '../providers/intecom.service';
import { RawDataService } from '../providers/RawData.service';
import { Subscription } from 'rxjs';
import { iRawDataDTOOUT, iRawDataFullDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { Logger, LogLevel } from 'ask-logger';
import { RawDataAssembler } from 's3qr-dnadto/src/assembler/RawDataAssembler';
import { iNormalizedDataDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { thresholdFreedmanDiaconis } from 'd3-array';
import { TagPlaceholder } from '@angular/compiler/src/i18n/i18n_ast';
import { iNormalizedDataElementDTO } from 's3qr-dnadto/src/dto/NormalizedDataElementDTO';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNormalizedDataFormComponent } from '../addNormalizedDataForm/addNormalizedDataForm.component';
import { NormalizedDataElement } from '../models/NormalizedDataElement';
import { MatTable } from '@angular/material/table';
import { KeycloakService } from 'keycloak-angular';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';


const LOGGER = Logger.getLogger('NormalizedDataEditorComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-normalizedDataEditor',
  templateUrl: './normalizedDataEditor.component.html',
  styleUrls: ['./normalizedDataEditor.component.css']
})
export class NormalizedDataEditorComponent extends logged implements OnInit {

  subscription_RawData: Subscription;
  subscription_NormalizedData: Subscription;
  subscription_NormalizedDataGraph: Subscription;
  data: iRawDataFullDTOOUT;
  normalizedData: iNormalizedDataDTO;
  displayedColumns: string[] = ['id', 'dye_Peak', 'area', 'n_size', 'n_height', 'delete'];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;


  constructor(public dialog: MatDialog, protected comunication: KcConnectService, private rawDataService: RawDataService, private _snackBar: MatSnackBar, private intercom: IntecomService, protected keycloakService: KeycloakService) {
    super(keycloakService, comunication);


    this.subscription_RawData = this.intercom.dnaAnalysisForm$.subscribe((message: iRawDataDTOOUT) => {

      LOGGER.info("Message arrived :", message)
      const rawData: iRawDataDTOOUT = message;
      this.rawDataService.getRawDataById(rawData.id).then(async (result: iRawDataFullDTOOUT) => {
        this.data = result;
        const token_value = await this.keycloakService.getToken();
        this.token = await this.comunication.loggedIn_data(token_value);
        this.normalizedData = RawDataAssembler.fromRawDataToNormalizedData(result, this.token.user.name, "");
        this.normalizedData.heightFilterNoise = true;
        LOGGER.info("RAW : ", result)
        LOGGER.info("NORMALIZED : ", this.normalizedData)
        this.normalizedData.maxHeight = 100;
      }).catch(e => {
        this._snackBar.open("Error retrieving data from server", "Ok");
        LOGGER.error(e)
      })
    })

    this.subscription_NormalizedData = this.intercom.normalizedDataForm$.subscribe((message) => {
      // LOGGER.info("NORMALIZED DATE REFRESH ", message)
      this.normalizedData = message;

    })


   }

  ngOnInit() {


  }

  ngOnDestroy() {

  }

  delete(todelete : iNormalizedDataElementDTO) {
    LOGGER.info(todelete)
    LOGGER.info("NORMALIZED DATA SIZE BEFORE : " + this.normalizedData.normalizedDataElements.length)
    this.normalizedData.normalizedDataElements = this.normalizedData.normalizedDataElements.filter(x => x.id != todelete.id)
    LOGGER.info("NORMALIZED DATA SIZE AFTER : " + this.normalizedData.normalizedDataElements.length)
    let points: any[] = []
    this.normalizedData.normalizedDataElements.forEach((iN: iNormalizedDataElementDTO) => {
      points.push({ x: iN.n_size, y: iN.n_height })
    })
    this.normalizedData.points = points;
    this.intercom.refreshNormalizedDataGraph(this.normalizedData)
  }

  update() {
    let points : any[] = []
    this.normalizedData.normalizedDataElements.forEach((iN: iNormalizedDataElementDTO) => {
      points.push({ x: iN.n_size, y: iN.n_height })
    })
    this.normalizedData.points = points;
    LOGGER.info("NORMALIZED graph DATa TO SEND ", this.normalizedData)
    this.intercom.refreshNormalizedDataGraph(this.normalizedData)
  }

  addRow() {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = new NormalizedDataElement();
    const dialogRef = this.dialog.open(AddNormalizedDataFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("-- arrivato M  -->", result)
      this.normalizedData.normalizedDataElements.push(result);
      let points: any[] = []
      this.normalizedData.normalizedDataElements.forEach((iN: iNormalizedDataElementDTO) => {
        points.push({ x: iN.n_size, y: iN.n_height })
      })
      this.normalizedData.points = points;
      console.log("-->", this.normalizedData)
      this.table.renderRows();
      this.intercom.refreshNormalizedDataGraph(this.normalizedData)
    });
  }

}
