<mat-card class="distanza">
  <mat-card-title>SELECT A RAW DATA FROM DATABASE</mat-card-title>

  <div class="row">
  <div class="column_sx">
   <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Digit or select code</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [(ngModel)]="selected"
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="column_dx">

      <div class="column_int">
        <button class="bottone" (click)="go()" mat-raised-button color="warn">
          <mat-icon> bar_chart</mat-icon>Show Graph
        </button>
      </div>
      <div class="column_int">
        <button class="bottone" (click)="reset()" mat-raised-button>
          <mat-icon>refresh</mat-icon> Reset
        </button>
      </div>
    </div>

  </div>

  <div id="comp-render" *ngIf="_showgraph">
    <mat-card-subtitle>NORMALIZED DATA FOR : {{ selected }}</mat-card-subtitle>
    <app-dnagraph></app-dnagraph>
    <br />
    <br />
    <mat-card-title>NORMALIZED DATA EDITOR : </mat-card-title>
    <br />
    <app-normalizedDataEditor></app-normalizedDataEditor>
  </div>
</mat-card>
