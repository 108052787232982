import { Component, OnInit } from '@angular/core';
import { NORMALIZED_LIST_TYPE, DATABASE_LIST_TYPE } from '../models/ServiceEnum';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntecomService } from '../providers/intecom.service';
import { outDNADatabaseDTO } from 's3qr-dnadto/src/dto/DNADatabaseDTO';
import { iAnalysisInDTO, iAnalysisOutDTO } from 's3qr-dnadto/src/dto/AnalysisDTO';
import { Logger, LogLevel } from 'ask-logger';
import { iNormalizedDataDTO, iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import * as uuid from 'uuid';
import { DatabaseService } from '../providers/Database.service';
import { InvestigationService } from '../providers/Investigation.service';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { CategoryResult, fillInvestigationResult, InvestigationResult, SingleResult } from '../models/InvestigationResult';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InvestigationResultsDetailsComponent } from '../InvestigationResultsDetails/InvestigationResultsDetails.component';
import { NullTemplateVisitor } from '@angular/compiler';
const LOGGER = Logger.getLogger('InvestigationMainComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-InvestigationMain',
  templateUrl: './InvestigationMain.component.html',
  styleUrls: ['./InvestigationMain.component.css']
})
export class InvestigationMainComponent implements OnInit {


  _show_result: boolean = false;

  k: number = 1;
  p: number = 1;
  peak_number: number = 1

  typeNormalizedDataList: NORMALIZED_LIST_TYPE = NORMALIZED_LIST_TYPE.INVESTIGATION
  typeDatabaseList: DATABASE_LIST_TYPE = DATABASE_LIST_TYPE.INVESTIGATION

  subscriptionSelectedDatabase: Subscription;
  subscriptionSelectedNormalizedData: Subscription;

  database_selected: outDNADatabaseDTO;
  normalizedData_selected: iNormalizedDataLISTDTO;

  databse_normalizedData: iNormalizedDataDTO[];

  investigation_results: iAnalysisOutDTO[] = []

  constructor(private nDataService: DatabaseService, private investigationService:
    InvestigationService, private normalizedDataService: NormalizedDataService, private _snackBar: MatSnackBar, private intercom: IntecomService, public dialog: MatDialog) { }

  ngOnInit() {

    this.subscriptionSelectedDatabase = this.intercom.selectedDatabase$.subscribe((message: outDNADatabaseDTO) => {
      this.database_selected = message;
      LOGGER.info("Selected  dB : ", this.database_selected)

    });

    this.subscriptionSelectedNormalizedData = this.intercom.selectedNormalized$.subscribe((message: iNormalizedDataLISTDTO) => {
      this.normalizedData_selected = message;
      LOGGER.info("Selected  Nd : ", this.normalizedData_selected)
    });

  }

  ngOnDestroy() {
    this.subscriptionSelectedDatabase.unsubscribe();
    this.subscriptionSelectedNormalizedData.unsubscribe();
  }

  go() {
    let is_possible: boolean = true;
    if (!this.database_selected) {
      this._snackBar.open("Please Select a Database", "Ok");
      is_possible = false;
    }
    if (!this.normalizedData_selected) {
      this._snackBar.open("Please Select a normalized Data", "Ok");
      is_possible = false;
    }
    if (!this.peak_number) {
      this._snackBar.open("Please Select a Peak Number", "Ok");
      is_possible = false;
    }
    if (is_possible) {
      this.investigation_results = [];
      this.normalizedDataService.getNormalizedDataById(this.normalizedData_selected.id).then((normalized_data_result_to_investigate: iNormalizedDataDTO) => {
        LOGGER.info("ARRIVATO (normalized_data_result) : ", normalized_data_result_to_investigate)
        this.nDataService.getNormalizedDataFull(this.database_selected.id).then((normalized_datas_result: iNormalizedDataDTO[]) => {
          LOGGER.info("ARRIVATO (normalized_datas_result)", normalized_datas_result);
          this.databse_normalizedData = normalized_datas_result;
          this.databse_normalizedData.forEach(x => {

             const toAnalize: iAnalysisInDTO = {
              id: uuid.v4() ,
              sample: x,
              subject: normalized_data_result_to_investigate,
              peak_number: this.peak_number
            }

            this.investigationService.investigate(toAnalize).then((result ) => {
              LOGGER.info("ARRIVATA ANALISI : ", result)
              let to_add: iAnalysisOutDTO;
              try {
                  to_add = JSON.parse(result)
              } catch (error) {
                LOGGER.error(error)
                to_add = {
                  id: result.id,
                  sample: result.sample,
                  value: Number.MAX_VALUE
                }
                LOGGER.info("ANALISYS CORRECTION : ", to_add)
              }
              this.investigation_results.push(to_add)
              if (this.investigation_results.length == normalized_datas_result.length) {
                this.show_investigation_results();
              }

            }).catch(e => {
              LOGGER.error("3-", e)
            })

          })



        }).catch(e => {
          LOGGER.error("2-",e)
        })
      }).catch(e => {
        LOGGER.error("1-", e)
      })



    }


  }

  show_investigation_results() {

    LOGGER.info("=============================================")
    console.log(JSON.parse(JSON.stringify(this.investigation_results[0])).sample)
    LOGGER.info("=============================================")
    // modifica del p




    let categoryMap = new Map<string, iNormalizedDataDTO[]>();
    let ndMap = new Map<string, iNormalizedDataDTO>();
    let resultMap = new Map<string, iAnalysisOutDTO>();
    this.databse_normalizedData.forEach(nd => {
      ndMap.set(nd.id, nd)
      let temp: iNormalizedDataDTO[] = []
      if (categoryMap.has(nd.category)) {
        temp = categoryMap.get(nd.category);
      }
      temp.push(nd);
      categoryMap.set(nd.category, temp);
    })
    this.investigation_results.forEach(ir => {
      resultMap.set(ir.sample,ir)
    })
    let data: CategoryResult[] = [];
    categoryMap.forEach((value: iNormalizedDataDTO[], key: string) => {
      let singleresults: SingleResult[] = [];
      let max : number = 0;
      let min: number = Number.MAX_VALUE;
      let sum: number = 0;
      let sum_square: number = 0;
      let AVG: number = 0;
      let VAR: number = 0;

      value.forEach(nd => {
        let sr: SingleResult = {
          id: nd.id,
          code: ndMap.get(resultMap.get(nd.id).sample).rawdata.code,
          distance: Math.pow(resultMap.get(nd.id).value,this.p),
          is_k: false,

          similarity: null,
          is_k_sim: null
        }
        singleresults.push(sr)
        max = resultMap.get(nd.id).value > max ? resultMap.get(nd.id).value : max;
        min = resultMap.get(nd.id).value < min ? resultMap.get(nd.id).value : min;
        sum = sum + resultMap.get(nd.id).value;

      })

      AVG = sum / value.length;

      singleresults.forEach(x => {
        LOGGER.info("=============================")
        LOGGER.info("AVG ", sum_square)
        LOGGER.info("val ", x.distance)
        LOGGER.info("op ", ((x.distance - AVG) * (x.distance - AVG)))

        sum_square = sum_square + ((x.distance - AVG) * (x.distance - AVG));
        LOGGER.info("sum_square ", sum_square)
      })
      LOGGER.info("sumSqare ", sum_square)
      VAR = sum_square / (value.length-1)


      let cat: CategoryResult = {
        name: key,
        results: singleresults,
        average: AVG,
        variance: VAR,
        max: max,
        min: min,
        max_prob: null,
        min_prob: null,
        max_similarity: null,
        min_similarity: null,
        prob: null
      }
      data.push(cat)
    });
    LOGGER.info("====================================================")
    LOGGER.info(data)
    // let dialogConfig = new MatDialogConfig();
    // dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 1024;
    let toSend: InvestigationResult = {
      k: this.k,
      categories: data,
      gini: null
    }




    fillInvestigationResult(toSend);


    this._show_result = true;
    this.intercom.investigationResultArrived(toSend);
    // dialogConfig.data = toSend
    // const dialogRef2 = this.dialog.open(InvestigationResultsDetailsComponent, dialogConfig);
    // dialogRef2.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   if (result) {
    //     this._snackBar.open("OK", "Ok");

    //   } else {
    //     LOGGER.info("non salvato")
    //   }


    // });
  }

  back() {
    this._show_result = false;
  }

}
