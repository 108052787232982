<div class="forminput">
  <mat-label>Upload Pathogens Analysis File</mat-label><br />
  <br />

  <div class="row distancetop">
    <input type="file" (change)="getFile($event)" id="filesdna" #filesdna />
  </div>

  <br />

  <button
    mat-stroked-button
    color="warn"
    [hidden]="!isThereAGraph"
    (click)="clearGraph($event); (false)"
  >
    Remove the Graph
  </button>
  <!-- <canvas #lineCanvas></canvas> -->
  <div id="barChart3"></div>
  <!--<svg width="960" height="500"></svg>-->
</div>
