<mat-card-title>NEW DATABASE</mat-card-title>

<div class="column_scxg">
  <div class="row">
    <div class="column">
      <button
        class="bottone"
        mat-raised-button

      >
        <mat-icon>refresh</mat-icon> Refresh
      </button>
    </div>
    <div class="column">
      <button
        class="bottone"
        mat-raised-button
        color="warn"
        (click)="save()"
      >
        <mat-icon>note_add</mat-icon> Database
      </button>
    </div>
  </div>
</div>
<table id="a" mat-table [dataSource]="normalizedData2" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>code</th>
    <td mat-cell *matCellDef="let element">
      {{ element.rawdata ? element.rawdata.code : " " }}
    </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>category</th>
    <td mat-cell *matCellDef="let element">{{ element.category }}</td>
  </ng-container>
  <ng-container matColumnDef="filterH">
    <th mat-header-cell *matHeaderCellDef>filterH</th>
    <td mat-cell *matCellDef="let element">{{ element.filterH }}</td>
  </ng-container>

  <ng-container matColumnDef="filterS">
    <th mat-header-cell *matHeaderCellDef>filterS</th>
    <td mat-cell *matCellDef="let element">{{ element.filterS }}</td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef>Author</th>
    <td mat-cell *matCellDef="let element">{{ element.author }}</td>
  </ng-container>

  <ng-container matColumnDef="remove">
    <th mat-header-cell *matHeaderCellDef>Remove</th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="remove(element)" color="warn" aria-label="Remove">
        <mat-icon>remove</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
