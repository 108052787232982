import { Component, Inject, OnInit } from '@angular/core';
import {   MatDialogRef,   MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RawDataElement } from '../models/RawDataElement';
import { iRawDataDTOIN } from 's3qr-dnadto/src/dto/RawDataDTO';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { logged } from '../support/logged';
import { KcConnectService } from '../providers/kc-connect.service';
import { environment } from '../../environments/environment';
import { CategoryService } from '../providers/Category.service';
import { RawDataService } from '../providers/RawData.service';
import { iCategoryOut } from 's3qr-dnadto/src/dto/CategoryDTO';

import { MatSnackBar } from '@angular/material/snack-bar';
import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('RawDataFormComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-rawDataForm',
  templateUrl: './rawDataForm.component.html',
  styleUrls: ['./rawDataForm.component.scss']
})

export class RawDataFormComponent extends logged implements OnInit {
  env: any;
  private rawdata: iRawDataDTOIN;
  registerForm: FormGroup;
  categories: iCategoryOut[] = [];
  rawDataFileName: string = "";
  selected_category: iCategoryOut;
  no_category: string = "NO CATEGORY"


  constructor(@Inject(MAT_DIALOG_DATA) public data: RawDataElement[],
    protected comunication: KcConnectService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private categoryService: CategoryService,
    private rawDataService: RawDataService,
    public dialogRef: MatDialogRef<any>,
    protected keycloakService: KeycloakService) {

    super(keycloakService, comunication);
    this.env = environment;

    if (data) {
      LOGGER.info(data[0].$sample_file_Name)
      this.rawDataFileName = data[0].$sample_file_Name.replace(".fsa", "")
    }
  }

  ngOnInit() {
    this.categoryService.getCategories().then(r => {
      LOGGER.info(r);
      this.categories = r;
      this.selected_category = this.categories.find(x => {
        return x.name == "Unknown" && x.type == "UNKNOWN"
      })
    })

  }

  saveRawData() {
    LOGGER.info(this.selected_category);
    LOGGER.info(this.rawDataFileName);
    LOGGER.info(this.token.user.name);
    if (!this.selected_category) {
        this._snackBar.open("No category selected! Please select one", "Ok");
    } else {
      this.rawdata = {
        author: this.token.user.name,
        category : this.selected_category.name,
        code : this.rawDataFileName
      }

      LOGGER.info(this.rawdata);

      this.rawDataService.postRawData(this.rawdata).then((result) => {
        this._snackBar.open("RawData created successfully..", "Ok");
        LOGGER.info(result)
        let inserte_rawdata: iRawDataDTOOUT = result;
        this.rawDataService.postRawDataElements(inserte_rawdata.id, this.data).then((result2) => {
          LOGGER.info(result2)
          this._snackBar.open("RawDataElements Saved successfully!", "Ok");
          this.dialogRef.close(this.selected_category.type + " " + this.selected_category.name);
        }).catch(e => {
          this._snackBar.open("ERROR saving RawdataElements", "Ok");
          LOGGER.error(e)

        })


      }).catch(e => {
        this._snackBar.open("ERROR saving Rawdata", "Ok");
        LOGGER.error(e)
      })

    }


  }

}

