 <div class="row">
  <div class="column_sx">
     <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Search for Raw Data</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        (ngModelChange)="change($event)"
        [(ngModel)]="selected"

      />
   </mat-form-field>
  </div>
  <div class="column_dx">

      <div class="column_int">
        <!-- <button class="bottone" (click)="go()" mat-raised-button color="warn">
          <mat-icon>search</mat-icon>Search
        </button> -->
      </div>
        <div class="column_int">
           <button class="bottone" (click)="go()" mat-stroked-button  color="warn" >
          <mat-icon>search</mat-icon>Search
        </button>
        <!-- <button class="bottone" (click)="newDb()" mat-raised-button color="warn">
          <mat-icon>note_add</mat-icon> DataBase
        </button> -->
      </div>
    </div>

  </div>
