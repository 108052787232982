import { Component, Input, OnInit } from '@angular/core';
import { Logger, LogLevel } from 'ask-logger';
import { DatabaseService } from '../providers/Database.service';
import { IntecomService } from '../providers/intecom.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { outDNADatabaseDTO } from '../../../../s3qr-DNADTO/src/dto/DNADatabaseDTO';
import { NormalizedDataListDialogComponent } from '../normalizedDataListDialog/normalizedDataListDialog.component';
import { DATABASE_LIST_TYPE } from '../models/ServiceEnum';
const LOGGER = Logger.getLogger('DatabaseListComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-databaseList',
  templateUrl: './databaseList.component.html',
  styleUrls: ['./databaseList.component.css']
})
export class DatabaseListComponent implements OnInit {


  @Input()
  modeSelect: DATABASE_LIST_TYPE;

  subscription_ToShowDatabase: Subscription;

  databases: outDNADatabaseDTO[];
  displayedColumns: string[] = [];


  constructor(private nDataService: DatabaseService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) {

  }

  ngOnInit() {
    this.databases = [];
    // if (this.modeSelect) {

    // } else {
    //   this.displayedColumns = ['code', 'category', 'filterH', 'filterS', 'author', 'desc', 'graph'];
    // }

    switch (this.modeSelect) {
      case DATABASE_LIST_TYPE.INVESTIGATION: {
        //statements;
        this.displayedColumns = ['name', 'author', 'creation', 'desc', 'info', 'select'];

        break;
      }

      default: {
        this.displayedColumns = ['name', 'author', 'creation', 'desc', 'info'];
        break;
      }
    }

    this.subscription_ToShowDatabase = this.intercom.databaseToShow$.subscribe((message: string[]) => {

      LOGGER.info("Message arrived :", message)
      if (message) {
        this.nDataService.getDatabases().then((response: outDNADatabaseDTO[]) => {
          LOGGER.info("Retrived :", response)
          if (response) {
            this.databases = [];
            response.forEach(x => {
              if (message.indexOf(x.id) >= 0) {
                this.databases.push(x)
              }
            });
            LOGGER.info("-->", this.databases)

          }
        }).catch((error) => {
          // this._snackBar.open("Error retrinving data", );
          LOGGER.info(error)
        })
      }

    })

  }
  ngOnDestroy(): void {
    this.databases = [];
    this.intercom.refreshNormalizedDataToShow([])
    this.subscription_ToShowDatabase.unsubscribe();
  }
  select(selected: outDNADatabaseDTO) {
    LOGGER.info("db selected", selected)
    this.intercom.selecteDatabaseToInvestigate(selected)
  }

  show(toShowDesc: outDNADatabaseDTO) {
    LOGGER.info(toShowDesc.desc)
    this._snackBar.open(toShowDesc.desc, "ok");
  }
  info(toShowDesc: outDNADatabaseDTO) {
    LOGGER.info(toShowDesc.desc)
    LOGGER.info("[save]");

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 1024;

    dialogConfig.data = toShowDesc
    const dialogRef2 = this.dialog.open(NormalizedDataListDialogComponent , dialogConfig);
    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this._snackBar.open("OK", "Ok");

      } else {
        LOGGER.info("non salvato")
      }


    });
  }

}
