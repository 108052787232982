<mat-tab-group mat-align-tabs="end">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon> insert_chart_outlined</mat-icon>
      Graph
    </ng-template>
    <ng-template matTabContent>
      <app-InvestigationGraph></app-InvestigationGraph>
    </ng-template>
  </mat-tab>
<mat-tab>
    <ng-template mat-tab-label>
      <mat-icon> score</mat-icon>
      Results
    </ng-template>
     <app-InvestigationResultsDetails></app-InvestigationResultsDetails>
  </mat-tab>
</mat-tab-group>
