import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Logger, LogLevel } from 'ask-logger';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { IntecomService } from '../providers/intecom.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


import { Subscription } from 'rxjs';
import { NormalizedGraphShowComponent } from '../normalizedGraphShow/normalizedGraphShow.component';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { NORMALIZED_LIST_TYPE } from '../models/ServiceEnum';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
const LOGGER = Logger.getLogger('NormalizedDataListComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-normalizedDataList',
  templateUrl: './normalizedDataList.component.html',
  styleUrls: ['./normalizedDataList.component.scss']
})
export class NormalizedDataListComponent implements OnInit {

  @Input()
  modeSelect: NORMALIZED_LIST_TYPE;


  subscription_ToShowNormalizedData: Subscription;

  normalizedData: MatTableDataSource<iNormalizedDataLISTDTO>;
  displayedColumns: string[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  selected: iNormalizedDataLISTDTO;

  constructor(private nDataService: NormalizedDataService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) {

  }

  ngOnInit() {

    this.normalizedData = new MatTableDataSource<iNormalizedDataLISTDTO>();
    switch (this.modeSelect) {
      case NORMALIZED_LIST_TYPE.DATABASE: {
        //statements;
        this.displayedColumns = ['code', 'title','category', 'filterH', 'filterS', 'author', 'desc', 'graph', 'add'];
        break;
      }
      case NORMALIZED_LIST_TYPE.INVESTIGATION: {
        //statements;
        this.displayedColumns = ['code', 'title','category', 'filterH', 'filterS', 'author', 'desc', 'graph','select'];
        break;
      }
      default: {
        this.displayedColumns = ['code', 'title','category', 'filterH', 'filterS', 'author', 'desc', 'graph'];
        break;
      }
    }

    this.subscription_ToShowNormalizedData = this.intercom.normalizedDataToShow$.subscribe((message : string[]) => {

      LOGGER.info("Message arrived :", message)
      if (message) {
        this.nDataService.getNormalizedData().then((response: iNormalizedDataLISTDTO[]) => {
          if (response) {
            this.normalizedData = new MatTableDataSource<iNormalizedDataLISTDTO>();
            response.forEach(x => {
              if (message.indexOf(x.id) >= 0) {
                this.normalizedData.data.push(x)
              }
            });
            this.normalizedData.paginator = this.paginator;
            this.normalizedData.sort = this.sort;
          }
        }).catch((error) => {
          // this._snackBar.open("Error retrinving data", );
          LOGGER.info(error)
        })
      }

    })

  }
  ngOnDestroy(): void {
    this.normalizedData = new MatTableDataSource<iNormalizedDataLISTDTO>();
    this.intercom.refreshNormalizedDataToShow([])
    this.subscription_ToShowNormalizedData.unsubscribe();
  }

  show(toShowDesc: iNormalizedDataLISTDTO) {
    LOGGER.info(toShowDesc.desc)
    this._snackBar.open(toShowDesc.desc,"ok");
  }
  add(toShowDesc: iNormalizedDataLISTDTO) {
    this.intercom.addNormalizedtoDatabase(toShowDesc)
  }

  select(_selected: iNormalizedDataLISTDTO) {
    LOGGER.info("nd selected", _selected)
    this.selected = _selected
    this.intercom.selecteNormalizedDataToInvestigatee(this.selected);
  }
  isSelected(_selected: iNormalizedDataLISTDTO) {

      return   this.selected ? this.selected.id == _selected.id : false
  }

  graph(toGraph: iNormalizedDataLISTDTO) {
    LOGGER.info("[save]");

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 1024;

    dialogConfig.data = toGraph
    const dialogRef2 = this.dialog.open(NormalizedGraphShowComponent, dialogConfig);
    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this._snackBar.open("OK", "Ok");

      } else {
        LOGGER.info("non salvato")
      }


    });
  }
  }


