<mat-card class="distanza">
  <mat-card-title>UPDATE ANALYSIS FOR LOT {{ lot_lot }} </mat-card-title>
  <mat-card-content>
    <form [formGroup]="registerForm" (ngSubmit)="addgeneric_Report()">
      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>LAB ID</mat-label>
            <input
              matInput
              readonly
              formControlName="labid"
              [(ngModel)]="generic_Report.lab_id"
              placeholder="Laboratory Name"
            />
            <div *ngIf="submitted && f.labid.errors" class="invalid-feedback">
              <div *ngIf="f.labid.errors.required">Lab_ID is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Lot ID</mat-label>
            <input
              matInput
              readonly
              formControlName="lotid"
              [(ngModel)]="lot_id"
              placeholder="Lot ID"
            />
            <div *ngIf="submitted && f.lotid.errors" class="invalid-feedback">
              <div *ngIf="f.lotid.errors.required">LOT_ID is required</div>
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="forminput">
          <mat-label>Analysis Title</mat-label>
          <input
            matInput
            formControlName="title_result"
            [(ngModel)]="generic_Report.title_result"
            placeholder="Analysis Title"
          />
          <div
            *ngIf="submitted && f.title_result.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.title_result.errors.required">
              Analysis Title is required
            </div>
          </div>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-label class="forminput">Analysis Description</mat-label>
        <div class="editor">
          <ejs-richtexteditor
            formControlName="text_result"
            required
            [toolbarSettings]="tools"
            [(ngModel)]="generic_Report.text_result"
            placeholder="Analysis Description"
          ></ejs-richtexteditor>
        </div>

        <div *ngIf="submitted && f.text_result.errors" class="invalid-feedback">
          <div *ngIf="f.text_result.errors.required">
            Analysis Title is required
          </div>
        </div>
      </div>

      <div class="row">
        <div class="column_image_sx">

           <button
            *ngIf="generic_Report.image"
            mat-stroked-button
            type="button"
            color="warn"
            class="distanza4"
            (click)="showimage(generic_Report.image)"
          >
            Current Image
          </button>

        </div>
        <div class="column_image_dx">
         <div class="forminput">
            <mat-label>Upload/change image illustrating the results of data analysis </mat-label><br />
            <input
              type="file"
              (change)="getLogo($event)"
              id="filesLogo"
              #filesLogo
              accept="image/*"
            />
          </div>
        </div>
      </div>
      <div *ngIf="enable_dna">
        <app-dnagraphform [points]="generic_Report.other_result"></app-dnagraphform>
      </div>
        <div *ngIf="enable_patogeni">
        <app-patogeni-graph [_graphs]="generic_Report.other_result" ></app-patogeni-graph>
      </div>
      <button mat-raised-button color="warn" class="distanza2">
        Update Analysis Lab
      </button>
    </form>
    <!--
     <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="sendTestMessage()"
          >
            SEND TEST MESSAGE
          </button> -->
  </mat-card-content>
</mat-card>
