import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { outDNADatabaseDTO } from 's3qr-dnadto/src/dto/DNADatabaseDTO';
import { DatabaseService } from '../providers/Database.service';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('DatabaseFinderComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-databaseFinder',
  templateUrl: './databaseFinder.component.html',
  styleUrls: ['./databaseFinder.component.css']
})
export class DatabaseFinderComponent implements OnInit {

  myControl = new FormControl();
  data: outDNADatabaseDTO[] = [];
  options: string[] = [];
  alloptions: string[] = [];
  selected: string;
  _showgraph: boolean = false;
  filteredOptions: Observable<string[]>;


  constructor(private nDataService: DatabaseService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.nDataService.getDatabases().then((response: outDNADatabaseDTO[]) => {
      LOGGER.info("Data Retrived", response)
      if (response) {
        this.data = response;
        this.data.forEach(x => {
          this.alloptions.push(x.id + "#" + x.author + " " + x.desc + " " + x.name)
        });
      }
    }).catch((error) => {
      this._snackBar.open("Error retrinving data");
      LOGGER.info(error)
    })
  }

  change(event) {
    LOGGER.info("valore da filtrare ->" + event)
    const filterValue = event.toLowerCase();
    this.options = this.alloptions.filter(x => x.toLowerCase().includes(filterValue))
  }

  go() {
    LOGGER.info(this.options)
    const toSend: string[] = [];
    if (this.options) {
      this.options.forEach(x => {
        if (x.split("#").length > 0) {
          toSend.push(x.split("#")[0]);
        }
      })
    }
    LOGGER.info(toSend)
    this.intercom.refreshDatabaseToShow(toSend)
  }



}
