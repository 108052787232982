<mat-card-title>BULK IMPORT RAW DATA</mat-card-title>
<div class="row">
  <div class="column_sx">
    <mat-label>SELECT A CATEGORY : </mat-label>
    <mat-form-field class="formfield" appearance="fill">
      <mat-select [(value)]="selected_category">
        <mat-option *ngFor="let category of categories" [value]="category">
          {{ category.type }} - {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="column_dx">
    <button
      class="bottone"
      mat-raised-button
      color="warn"
      (click)="saveRawData()"
    >
      Save
    </button>
  </div>
</div>

<br />
<mat-progress-bar color="warn" mode="determinate" [value]="progress_saved">
</mat-progress-bar>
