import { Component, OnInit } from '@angular/core';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('DatabaseMainComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-databaseMain',
  templateUrl: './databaseMain.component.html',
  styleUrls: ['./databaseMain.component.scss']
})
export class DatabaseMainComponent implements OnInit {

  constructor(private intercom: IntecomService) { }
  ngOnInit() {
  }

}
