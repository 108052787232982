
  <div class="column_scxg">
    <div class="row">
      <div class="column">
         <button class="bottone" mat-mini-fab color="warn" (click)="update()" aria-label="Update the Graph (when you modify manually some values)" >
      <mat-icon>refresh</mat-icon>
    </button>
      </div>
       <div class="column">
         <button class="bottone" mat-mini-fab color="warn" (click)="addRow()" aria-label="Add a custom row" >
      <mat-icon>control_point</mat-icon>
    </button>
      </div>
    </div>

  </div>

<table mat-table [dataSource]="normalizedData.normalizedDataElements" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

<!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="dye_Peak">
    <th mat-header-cell *matHeaderCellDef> Dye_Peak </th>
    <td mat-cell *matCellDef="let element"> {{element.dye_Peak}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="area">
    <th mat-header-cell *matHeaderCellDef> Area </th>
    <td mat-cell *matCellDef="let element"> {{element.area}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="n_size">
    <th mat-header-cell *matHeaderCellDef> Size </th>
    <td mat-cell *matCellDef="let element">   <mat-form-field floatLabel="never">
					<input matInput type="number"  [value]="element.n_size" [(ngModel)]="element.size">
				</mat-form-field></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="n_height">
    <th mat-header-cell *matHeaderCellDef> Height </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field floatLabel="never">
					<input matInput type="number"  [value]="element.n_height" [(ngModel)]="element.n_height">
				</mat-form-field>
      </td>
  </ng-container>
  <!-- Remove Column -->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef> Delete </th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="delete(element)" aria-label="Delete Element">
         <mat-icon>delete</mat-icon></button>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

