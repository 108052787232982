import { RawDataElement } from "./RawDataElement";

export class ImportList {
  filename: string;
  category: string;
  rawDataElements: RawDataElement[] = [];

  constructor(filename: string) {
    this.filename = filename;
  }


  addRawDataElement(e: RawDataElement) {
    this.rawDataElements.push(e)
  }





}
