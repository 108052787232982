import { ParserService } from './providers/parser.service';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { initializer } from './secure/kc-initializer';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { LotviewComponent } from './lotview/lotview.component';
import { Generic_messageComponent } from './generic_message/generic_message.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ShowimageComponent } from './showimage/showimage.component';
import { Generic_report_formComponent } from './generic_report_form/generic_report_form.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { LotcompletedviewComponent } from './lotcompletedview/lotcompletedview.component';
import { Generic_report_update_formComponent } from './generic_report_update_form/generic_report_update_form.component';
import { DnagraphformComponent } from './dnagraphform/dnagraphform.component';
import { MatRadioModule } from '@angular/material/radio';
import { showDescriptionComponent } from './showDescription/showDescription.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AnalysisGComponent } from './analysis-g/analysis-g.component';
import { DnagraphComponent } from './dnagraph/dnagraph.component';
import { PatogeniGraphComponent } from './patogeni-graph/patogeni-graph.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { MatIconModule } from '@angular/material/icon';
import { IspaformComponent } from './ispaform/ispaform.component';
import { RawDataFormComponent } from './rawDataForm/rawDataForm.component';
import { MatSelectModule } from '@angular/material/select';
import {  MatSnackBarModule } from '@angular/material/snack-bar';
import { RawDataFinderComponent } from './rawDataFinder/rawDataFinder.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NormalizedDataEditorComponent } from './normalizedDataEditor/normalizedDataEditor.component';
import { MatTableModule } from '@angular/material/table';
import { AddNormalizedDataFormComponent } from './addNormalizedDataForm/addNormalizedDataForm.component';
import { NormalizedDataSaveFormComponent } from './normalizedDataSaveForm/normalizedDataSaveForm.component';
import { NormalizedDataFinderComponent } from './normalizedDataFinder/normalizedDataFinder.component';
import { NormalizedDataMainComponent } from './normalizedDataMain/normalizedDataMain.component';
import { NormalizedDataListComponent } from './normalizedDataList/normalizedDataList.component';
import { DatabaseFormComponent } from './databaseForm/databaseForm.component';
import { DatabaseMainComponent } from './databaseMain/databaseMain.component';
import { DatabaseSaveFormComponent } from './databaseSaveForm/databaseSaveForm.component';
import { NormalizedGraphShowComponent } from './normalizedGraphShow/normalizedGraphShow.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DatabaseAddMainComponent } from './databaseAddMain/databaseAddMain.component';
import { DatabaseFinderComponent } from './databaseFinder/databaseFinder.component';
import { DatabaseListComponent } from './databaseList/databaseList.component';
import { NormalizedDataListDialogComponent } from './normalizedDataListDialog/normalizedDataListDialog.component';
import { CategoryMainComponent } from './categoryMain/categoryMain.component';
import { CategoryFormComponent } from './categoryForm/categoryForm.component';
import { CategoryListComponent } from './categoryList/categoryList.component';
import { InvestigationMainComponent } from './InvestigationMain/InvestigationMain.component';
import { InvestigationResultsDetailsComponent } from './InvestigationResultsDetails/InvestigationResultsDetails.component';
import { RawDataMainComponent } from './rawDataMain/rawDataMain.component';
import { RawDataFinder2Component } from './rawDataFinder2/rawDataFinder2.component';
import { RawDataListComponent } from './rawDataList/rawDataList.component';
import { RawDataGraphShowComponent } from './raw-data-graph-show/raw-data-graph-show.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RawDataBulkFormComponent } from './rawDataBulkForm/rawDataBulkForm.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InvestigationGraphComponent } from './InvestigationGraph/InvestigationGraph.component';
import { InfoComponent } from './info/info.component';
import { InvestigationResultTabComponent } from './InvestigationResultTab/InvestigationResultTab.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LotviewComponent,
    Generic_messageComponent,

    ShowimageComponent,
    Generic_report_formComponent,
    LotcompletedviewComponent,
    Generic_report_update_formComponent,
    DnagraphformComponent,
    showDescriptionComponent,
    AnalysisGComponent,
    DnagraphComponent,
    PatogeniGraphComponent,
      IspaformComponent,
      RawDataFormComponent,
      RawDataFinderComponent,
      NormalizedDataEditorComponent,
      AddNormalizedDataFormComponent,
      NormalizedDataSaveFormComponent,
      NormalizedDataFinderComponent,
      NormalizedDataMainComponent,
      NormalizedDataListComponent,
      DatabaseFormComponent,
      DatabaseMainComponent,
      DatabaseSaveFormComponent,
      NormalizedGraphShowComponent,
      DatabaseAddMainComponent,
      DatabaseAddMainComponent,
      DatabaseFinderComponent,
      DatabaseListComponent,
      NormalizedDataListDialogComponent,
      CategoryMainComponent,
      CategoryFormComponent,
      CategoryListComponent,
      InvestigationMainComponent,
    InvestigationResultsDetailsComponent,
      RawDataMainComponent,
      RawDataFinder2Component,
      RawDataListComponent,
      RawDataGraphShowComponent,

      RawDataBulkFormComponent,
      InvestigationGraphComponent,
      InfoComponent,
      InvestigationResultTabComponent
   ],
  imports: [
    MatAutocompleteModule,
    CommonModule,
    MatTableModule,
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatPaginatorModule,
    KeycloakAngularModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSortModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    HttpClientModule,
    MatIconModule,
    MatTabsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RichTextEditorAllModule,
    MatNativeDateModule,
    MatSliderModule,
    MatSlideToggleModule

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
