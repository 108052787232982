import { exit } from "process";
import { iNormalizedDataElementDTO } from "s3qr-dnadto/src/dto/NormalizedDataElementDTO";


export class NormalizedDataElement implements iNormalizedDataElementDTO {
  id: string;
  dye_Peak: string;
  sample_file_Name: string;
  marker: string;
  allele: string;
  size: string;
  height: string;
  area: string;
  data_Point: string;
  n_size?: number;
  n_height?: number;
  tempo?: number;
  e_value?: number;

  public static instanceOfNormalizedDataElementDTO(object: any): object is iNormalizedDataElementDTO {
    if (("id" in object) && ("n_size" in object) && ("n_height" in object)) {
      return true;
    } else {
      return false;
    }
  }

    constructor() {

  }

  public static of(o: any): NormalizedDataElement {
    if (this.instanceOfNormalizedDataElementDTO(o)) {
      try {
        let a: iNormalizedDataElementDTO = o as iNormalizedDataElementDTO;

        let exit = new NormalizedDataElement()
        exit.id = a.id;
        exit.dye_Peak = a.dye_Peak;
        exit.sample_file_Name = a.sample_file_Name;
        exit.marker = a.marker;
        exit.allele = a.allele;
        exit.size = a.size;
        exit.height = a.height;
        exit.area = a.area
        exit.data_Point = a.data_Point;
        exit.n_size = a.n_size
        exit.n_height = a.n_height
        exit.tempo = a.tempo
        exit.e_value = a.e_value
        // exit.validateState();
        return exit;
      } catch (e) {
        throw Error("[c] Can not parse! :" + JSON.stringify(o));
      }
    } else {
      throw Error("[d] Can not parse! :" + JSON.stringify(o));
    }
  }



}
