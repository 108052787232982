import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RawDataElement } from '../models/RawDataElement';
import { iRawDataDTOIN } from 's3qr-dnadto/src/dto/RawDataDTO';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { logged } from '../support/logged';
import { KcConnectService } from '../providers/kc-connect.service';
import { environment } from '../../environments/environment';
import { CategoryService } from '../providers/Category.service';
import { RawDataService } from '../providers/RawData.service';
import { iCategoryOut } from 's3qr-dnadto/src/dto/CategoryDTO';

import { MatSnackBar } from '@angular/material/snack-bar';
import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { Logger, LogLevel } from 'ask-logger';
import { ImportList } from '../models/ImportKey';
const LOGGER = Logger.getLogger('RawDataBulkFormComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-rawDataBulkForm',
  templateUrl: './rawDataBulkForm.component.html',
  styleUrls: ['./rawDataBulkForm.component.scss']
})
export class RawDataBulkFormComponent extends logged implements OnInit {
  env: any;

  registerForm: FormGroup;
  categories: iCategoryOut[] = [];
  rawDataFileName: string = "";
  selected_category: iCategoryOut;
  no_category: string = "NO CATEGORY"
  progress_saved: number = 1;
  saved: number = 0;
  errors: number = 0;
  saved_to_return: ImportList[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ImportList[],
    protected comunication: KcConnectService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private categoryService: CategoryService,
    private rawDataService: RawDataService,
    public dialogRef: MatDialogRef<RawDataBulkFormComponent>,


    protected keycloakService: KeycloakService) {

    super(keycloakService, comunication);
    this.env = environment;

    // if (data) {
    //   LOGGER.info(data[0].$sample_file_Name)
    //   this.rawDataFileName = data[0].$sample_file_Name.replace(".fsa", "")
    // }
  }

  ngOnInit() {
    this.categoryService.getCategories().then(r => {
      LOGGER.info(r);
      this.categories = r;
      this.selected_category = this.categories.find(x => {
        return x.name == "Unknown" && x.type == "UNKNOWN"
      })

    })

  }

  saveRawData() {
    LOGGER.info(this.selected_category);
    LOGGER.info(this.rawDataFileName);
    LOGGER.info(this.token.user.name);
    if (!this.selected_category) {
      this._snackBar.open("No category selected! Please select one", "Ok");
    } else {

      this.cilcleSave();


    }


  }

  increment() {
    this.saved = this.saved + 1;
    this.progress_saved = ( this.saved / this.data.length)*100
  }


  cilcleSave() {

    LOGGER.info("Tentativo upload numero : " + this.saved)
    if (this.saved >= this.data.length) {
      LOGGER.info("Uploaded : " + this.saved)
      LOGGER.info("Errors : " + this.errors)
      this.dialogRef.close(this.saved_to_return);
    } else {
      const filename = this.data[this.saved].rawDataElements[0].$sample_file_Name.replace(".fsa", "")
      const rawDataElements: RawDataElement[] = this.data[this.saved].rawDataElements;
      const rawdata: iRawDataDTOIN = {
        author: this.token.user.name,
        category: this.selected_category.name,
        code: filename
      }
      this.rawDataService.postRawData(rawdata).then((result) => {
        // this._snackBar.open("RawData created successfully..", "Ok");
        LOGGER.info(result)
        let inserte_rawdata: iRawDataDTOOUT = result;
        this.rawDataService.postRawDataElements(inserte_rawdata.id, rawDataElements).then((result2) => {
          LOGGER.info("RawElements upload : ", result2)
          // this._snackBar.open("RawDataElements Saved successfully!", "Ok");


          this.data[this.saved].category = this.selected_category.type + " " + this.selected_category.name
          this.saved_to_return.push(this.data[this.saved])
          this.increment();
          this.cilcleSave();
        }).catch(e => {
          this.errors = this.errors + 1;
          this._snackBar.open("ERROR saving RawdataElements:" + filename, "Ok");
          LOGGER.error(e)

        })
      }).catch(e => {
        this.errors = this.errors + 1;

        this._snackBar.open("ERROR saving Rawdata:" + filename, "Ok");
        LOGGER.error(e)
      })
    }
  }

}

