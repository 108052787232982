<div class="row">
  <div class="column" appearance="fill">
    <mat-form-field appearance="fill" class="formfields">
      <mat-label>SELECT A TYPE</mat-label>
      <mat-select  [(ngModel)]="_type">
        <mat-option *ngFor="let _category of category_type" [value]="_category">
          {{ _category }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="column">
    <mat-form-field appearance="fill" class="formfields">
       <mat-label>INSERT A NAME</mat-label>
      <input matInput [(ngModel)]="_name" />
    </mat-form-field>
  </div>
  <div class="column_save">
    <button class="bottone" mat-raised-button color="warn" (click)="saveCat()">
      Save
      <mat-icon>save</mat-icon>
    </button>
  </div>
</div>
<br>
