import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { RawDataService } from '../providers/RawData.service';
import { Logger, LogLevel } from 'ask-logger';
import { report } from 'process';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DnagraphComponent } from '../dnagraph/dnagraph.component';
import { IntecomService } from '../providers/intecom.service';
const LOGGER = Logger.getLogger('RawDataFinderComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-rawDataFinder',
  templateUrl: './rawDataFinder.component.html',
  styleUrls: ['./rawDataFinder.component.scss']
})
export class RawDataFinderComponent implements OnInit {

  myControl = new FormControl();
  data: iRawDataDTOOUT[] = [];
  options: string[] = [];
  selected: string;
  _showgraph : boolean = false;
  filteredOptions: Observable<string[]>;

  constructor(private rawDataService: RawDataService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    //  FIL DATA WITH DATABASES
    this.rawDataService.getAllRawData().then((response) => {

      if (response) {
        this.data = response;
        this.data.forEach(x => this.options.push(x.code + "@" + x.author +":" + x.category)  );
      }
    }).catch((error) => {
      this._snackBar.open("Error retrinving data", "Ok");
      LOGGER.info(error)
    })
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  go() {
    if (this.selected) {
      LOGGER.info("You have selected : " + this.selected)
      const code = this.selected.split("@")[0];
      const author = this.selected.split("@")[1].split(":")[0];
      const category = this.selected.split("@")[1].split(":")[1];
      LOGGER.info(code, author,category)
      const _selected: iRawDataDTOOUT = this.data.find(x => x.code === code && x.author === author && x.category === category);
      LOGGER.info(_selected)
      this._showgraph = true;
      this.intercom.dnaAnalysisRefrehForm(_selected)
      // let dialogConfig = new MatDialogConfig();
      // dialogConfig.autoFocus = true;
      // dialogConfig.minWidth = "1280";
      // dialogConfig.minHeight = "768";
      // dialogConfig.width = "90%";
      // dialogConfig.data = _selected;
      // this.dialog.open(DnagraphComponent, dialogConfig);
    } else {
      this._snackBar.open("No RawData selected!", "Ok");
    }

  }

  reset() {
    this._showgraph = false;
    this.selected = ""
  }

}
