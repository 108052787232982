import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { MyWebToken } from '../models/webtoken';
import { EventEmitter, Output } from '@angular/core';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('logged')
LOGGER.set_level(LogLevel.DEBUG)
export class logged {

  public token: MyWebToken;
  public name: string = "";
  public display: boolean = false;




  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService) {
    this.keycloakService.getToken().then((token_value: string) => {
      this.comunication.loggedIn_data(token_value).then((result: string) => {
        try {
          // console.log(result)
          this.token = MyWebToken.of(result);
          LOGGER.info(this.token)
          this.display = true;
        } catch (e) {
          LOGGER.error('Error2 :', e);
        }

      }).catch((e) => {
        LOGGER.error('Error :', e);
      });
    })

  }
}
