export enum NORMALIZED_LIST_TYPE {
  DATABASE,
  NORMALIZED,
  INVESTIGATION
}


export enum DATABASE_LIST_TYPE {
  DATABASE,
  INVESTIGATION
}
