import { Component, OnInit } from '@angular/core';
import { iCategoryIn, iCategoryOut } from 's3qr-dnadto/src/dto/CategoryDTO';
import { CATEGORY_TYPE } from '../models/CategoryTypeEnum';
import { Logger, LogLevel } from 'ask-logger';
import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { logged } from '../support/logged';
import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryService } from '../providers/Category.service';
import { IntecomService } from '../providers/intecom.service';
const LOGGER = Logger.getLogger('CategoryFormComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-categoryForm',
  templateUrl: './categoryForm.component.html',
  styleUrls: ['./categoryForm.component.css']
})
export class CategoryFormComponent extends logged implements OnInit {

  category_type: string[] = [];
  _name: string;
  _type: string;
  category: iCategoryIn;

  constructor(private intercom: IntecomService, protected comunication: KcConnectService, private categoryService: CategoryService, private _snackBar: MatSnackBar, protected keycloakService: KeycloakService ) {
    super(keycloakService, comunication);
    this.category_type[0] = CATEGORY_TYPE[CATEGORY_TYPE.ANIMAL];
    this.category_type[1] = CATEGORY_TYPE[CATEGORY_TYPE.VEGETABLE];
    this.category_type[2] = CATEGORY_TYPE[CATEGORY_TYPE.UNKNOWN];

  }

  ngOnInit() {
  }


  saveCat() {

    if (this._name) {
      if (this._type) {
        this.category = {
          name: this._name,
          type: this._type,
          author: this.token.user.name
        }
        this.categoryService.postCategories(this.category).then((response: iCategoryOut) => {
          this.intercom.addedCategory(response);
          this._name = ""
          this._type = ""
        }).catch(err => {
          LOGGER.error(err)
          this._snackBar.open("Something went wrong", "Ok");
        })
      } else {
        this._snackBar.open("No Type have been selected", "Ok");
      }
    } else {
      this._snackBar.open("No Name have been inserted", "Ok");
    }


    LOGGER.info(this.token.user.name);
    LOGGER.info("Category : ", this.category  )

  }
}
