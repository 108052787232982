import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categoryMain',
  templateUrl: './categoryMain.component.html',
  styleUrls: ['./categoryMain.component.css']
})
export class CategoryMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
