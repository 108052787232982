import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { IntecomService } from '../providers/intecom.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Generic_messageComponent } from '../generic_message/generic_message.component';
import { Generic_Report } from '../models/Generic_Report';
import { Generic_ReportService } from '../providers/generic_Report.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { Subscription } from 'rxjs';
import { Lot4Analysis } from '../models/Lot4Analysis';
import { ShowimageComponent } from '../showimage/showimage.component';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('Generic_report_update_formComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-generic_report_update_form',
  templateUrl: './generic_report_update_form.component.html',
  styleUrls: ['./generic_report_update_form.component.css']
})
export class Generic_report_update_formComponent implements OnInit {

  generic_Report: Generic_Report = new Generic_Report();
  registerForm: FormGroup;
  submitted = false;
  subscription: Subscription;
  enable_dna: boolean = false;
  enable_patogeni: boolean = false;
  dnaDataSubscription: Subscription;
  patogneniDataSubscription: Subscription;

  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      '|', 'FullScreen']
  };


  @ViewChild("filesLogo") filesLogo: ElementRef;
  @Output() refreshlistlabEvent = new EventEmitter<string>();

  @Input()
  labid: string;
  lot: Lot4Analysis;
  lot_lot: string = "";
  lot_id: string = "";

  constructor(public dialog: MatDialog, protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected generic_report_service: Generic_ReportService,
    private formBuilder: FormBuilder,
    private intercom: IntecomService) {


  }




  sendRefreshEvent() {
    this.refreshlistlabEvent.emit(environment.refreshlablist);
  }


  ngOnInit(): void {

    this.dnaDataSubscription = this.intercom.dnaDataForm$.subscribe((message) => {


        this.generic_Report.other_result = JSON.parse(message);

      LOGGER.info("----------------ARRIVED! DNA")

    });

    this.patogneniDataSubscription = this.intercom.patogeniDataForm$.subscribe((message) => {


        this.generic_Report.other_result = JSON.parse(message);

      LOGGER.info("----------------ARRIVED! PATOGENI")

    });
    this.generic_Report.lab_id = this.labid



    this.registerForm = this.formBuilder.group({
      labid: ['', Validators.required],
      lotid: ['', Validators.required],
      title_result: ['', Validators.required],
      text_result: ['', Validators.required],
      image: []
    });

    this.subscription = this.intercom.formitem$.subscribe((message) => {
      this.generic_Report = new Generic_Report()

      this.lot = Lot4Analysis.of(JSON.parse(message));

      this.generic_Report.lot_id = this.lot._id
      this.lot_id = this.lot._id
      this.lot_lot = this.lot.lot
      this.generic_Report.lab_id = this.labid
      this.generic_Report = Generic_Report.of(this.lot.getGeneric_Report_ByLAb(this.labid));

    });

    if (environment.dnaEnable.indexOf(this.labid) >= 0) {
      this.enable_dna = true;
    }
    if (environment.patogeniEnable.indexOf(this.labid) >= 0) {
      this.enable_patogeni = true;
    }
  }

  addgeneric_Report() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    this.lot.updateGeneric_Report_ByLab(this.labid, this.generic_Report)


    console.log(this.generic_Report)



    this.generic_report_service.addGeneric_Report(this.lot).then((res: boolean) => {
      console.log(res)

      if (res) {

        this.intercom.refreshList(environment.refreshlablist);
        dialogConfig.data = {
          title: environment.success,
        };
        this.submitted = false;
        this.filesLogo.nativeElement.value = "";
        this.generic_Report = new Generic_Report()
        this.generic_Report.insert_date = new Date();
        this.generic_Report.text_result = ""
      } else {
        dialogConfig.data = {
          title: environment.error,
        };
      }
      this.dialog.open(Generic_messageComponent, dialogConfig);
    }).catch((err) => {
      LOGGER.error("[addgeneric_Report]",err),
      dialogConfig.data = {
        title: environment.error,
      };
      this.dialog.open(Generic_messageComponent, dialogConfig);
    })
  }
  get f() { return this.registerForm.controls; }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  readThisLogo(inputValue: any): void {
    var file: File = inputValue.files[0];

    this.getBase64(file).then(result => {
      console.log(result.toString().length);
      this.generic_Report.image = result.toString();
    });
  }

  getLogo($event) {

    this.readThisLogo($event.target);


  }

  ngOnDestroy() {

    this.subscription.unsubscribe()
    this.dnaDataSubscription.unsubscribe()
    this.patogneniDataSubscription.unsubscribe()

  }

  sendTestMessage() {

    this.intercom.refreshList("inviao!");

  }

  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }
}
