import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { inDNADatabaseDTO } from '../../../../s3qr-DNADTO/src/dto/DNADatabaseDTO';
import { logged } from '../support/logged';
import { KeycloakService } from 'keycloak-angular';
import { KcConnectService } from '../providers/kc-connect.service';
import { DatabaseSaveFormComponent } from '../databaseSaveForm/databaseSaveForm.component';
const LOGGER = Logger.getLogger('NormalizedDataListComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-databaseForm',
  templateUrl: './databaseForm.component.html',
  styleUrls: ['./databaseForm.component.scss']
})
export class DatabaseFormComponent extends logged implements OnInit {

  subscription_addToDNA: Subscription;
  normalizedData2: MatTableDataSource<iNormalizedDataLISTDTO>;
  displayedColumns: string[] = ['code', 'category', 'filterH', 'filterS', 'author','remove'];
  database: inDNADatabaseDTO
  constructor(private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog, protected keycloakService: KeycloakService, protected comunication: KcConnectService) {
    super(keycloakService, comunication);
    this.normalizedData2 = new MatTableDataSource([]);
  }

  ngOnInit() {

    this.subscription_addToDNA = this.intercom.addNormalizedToDatabase$.subscribe((message: iNormalizedDataLISTDTO) => {

      LOGGER.info("Message arrived v addNormalizedToDatabase:", message)
      if (message) {

        // this.normalizedData2.push(message)
        const data = this.normalizedData2.data;
        data.push(message);
        this.normalizedData2.data = data;
        LOGGER.info("Lo aggiungo ", this.normalizedData2)
      }
    })



  }

  ngOnDestroy(): void {

    this.normalizedData2.data = [];


  }
  remove(toRemove: iNormalizedDataLISTDTO) {
    LOGGER.info("To remove ", toRemove)
    let data = this.normalizedData2.data;
    data = data.filter(x => x.id != toRemove.id);
    LOGGER.info("data ", data)
    this.normalizedData2.data = data;

  }

  save() {
    LOGGER.info("[save]");
    LOGGER.info(this.token.user.name);
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 600;

    dialogConfig.data = this.normalizedData2.data
    const dialogRef2 = this.dialog.open(DatabaseSaveFormComponent, dialogConfig);
    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this._snackBar.open("Saved Successfully", "Ok");
        this.normalizedData2.data = [];
      } else {
        LOGGER.info("non salvato")
      }


    });
  }

}
