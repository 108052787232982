import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('NormalizedDataMainComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-normalizedDataMain',
  templateUrl: './normalizedDataMain.component.html',
  styleUrls: ['./normalizedDataMain.component.scss']
})
export class NormalizedDataMainComponent implements OnInit {

  subscription_formToDNA: Subscription;



  constructor(private intercom: IntecomService) { }

  ngOnInit() {

  }

}
