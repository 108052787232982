import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { iCategoryIn } from '../../../../s3qr-DNADTO/src/dto/CategoryDTO';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }


  public async getCategories(): Promise<any> {
    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_categories).toPromise();

  }

  public async postCategories(tosend : iCategoryIn): Promise<any> {
    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.post(environment.main_url + environment.backend_dna_categories,tosend).toPromise();

  }

}
