<mat-card class="distanza">
  <mat-card-title> Save this Database: </mat-card-title>
  <br />

    <div class="row">
      <mat-label class="scritte">Author : {{ database.author }} </mat-label>
    </div>
     <br />
    <mat-label class="scritte">Database Name</mat-label>
    <div class="row2">
      <mat-form-field appearance="standard" class="forminput3">
        <input
          class="input1"
          matInput
          [(ngModel)]="database.name"
          placeholder="Database descripion, insert what you want."
         />
      </mat-form-field>
    </div>
    <br />
    <mat-label class="scritte">Database Description</mat-label>
    <div class="row">
      <mat-form-field appearance="standard" class="forminput2">
        <textarea
          rows="4"
          matInput
          [(ngModel)]="database.desc"
          placeholder="Database descripion, insert what you want."
        ></textarea>
      </mat-form-field>
    </div>

  <div class="row">
    <div class="column">
      <button
        class="bottonesx"
        mat-raised-button

        (click)="close()"
      >
        <mat-icon>close</mat-icon>Close
      </button>
    </div>
    <div class="bottone_dx">
      <button
        class="bottonedx"
        mat-raised-button
        color="warn"
        (click)="saveDatabase()"
      >
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>
</mat-card>
