import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KcConnectService } from './kc-connect.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('IdentityProviderService')
LOGGER.set_level(LogLevel.DEBUG)
@Injectable({
  providedIn: 'root'
})
export class IdentityProviderService {

  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService) {


  }


  public async getUser(): Promise<string>  {
    const token_value : string = await this.keycloakService.getToken()
    return this.comunication.loggedIn_data(token_value);
  }

}
