<div class="forminput grafico_top ">
  <div class="row  bg_form_color" >
    <div class="column_sxg ">
      <mat-label class="distanza4 " >Size Value:</mat-label>
      <mat-form-field class="distanza4" appearance="outline">
        <input matInput type="number" [(ngModel)]="normalizedData.filterS" />
      </mat-form-field>
    </div>
    <div class="column_dxg ">
      <mat-label class="scritta">Filter Size:</mat-label><br />

      <mat-slider
        class="distanza4"
        [max]="normalizedData.maxS"
        [(ngModel)]="normalizedData.filterS"
        thumbLabel="true"
        (change)="showgraph()"
      >
      </mat-slider>
    </div>
  </div>

  <div class="row   bg_form_color">
    <div class="column_sxg ">
      <p class="distanza4 scrittaV">
        {{ nameVerticalFilter }}
      </p>

      <mat-slider
        class="distanza6"
        [max]="normalizedData.maxHeight"
        vertical="false;"
        [(ngModel)]="normalizedData.filterH"
        thumbLabel="true"
        (change)="showgraph()"
      >
      </mat-slider>
      <mat-label class="distanza4 scrittaV">Height Value:</mat-label>
      <mat-form-field class="distanza4 " appearance="outline" >
        <input
          class="distanza4 scritta "
          matInput
          type="number"
          [(ngModel)]="normalizedData.filterH"
        />
      </mat-form-field>
    </div>

    <div class="column_dxg bg_grap_color grafico ">
      <!-- <canvas #lineCanvas></canvas> -->
      <div id="barChart3"></div>
      <!--<svg width="960" height="500"></svg>-->
    </div>
  </div>
</div>
<div class="forminput grafico_sotto">
<div class="row bg_form_color ">
  <div class="column_bdxg">
    <mat-label>Select Noise removal:</mat-label><br />
    <mat-radio-button
      value="true"
      [checked]="normalizedData.heightFilterNoise == true"
      (change)="radioChange($event)"
    >
      Height
    </mat-radio-button>
    <br />
    <mat-radio-button
      value="false"
      [checked]="normalizedData.heightFilterNoise == false"
      (change)="radioChange($event)"
    >
      Height*size^p
    </mat-radio-button>
  </div>
  <div class="column_bcxg  ">
    <p class="distanza4">p value :</p>
     <mat-form-field class="distanza4" appearance="outline">
    <input
      class="distanza4"
      matInput
      (ngModelChange)="valuechange($event)"
      [disabled]="normalizedData.heightFilterNoise"
      type="number"
      [(ngModel)]="normalizedData.exp"
    />
     <mat-hint *ngIf="normalizedData.heightFilterNoise">
      Disabled
    </mat-hint>
    </mat-form-field>
  </div>
  <div class="column_scxg">
    <button class="bottone" mat-raised-button color="warn" (click)="elabore()">
      <mat-icon>save</mat-icon>Save
    </button>
  </div>
</div>
</div>
