import { Component, OnInit } from '@angular/core';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
import { NORMALIZED_LIST_TYPE } from '../models/ServiceEnum';
const LOGGER = Logger.getLogger('DatabaseAddMainComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-databaseAddMain',
  templateUrl: './databaseAddMain.component.html',
  styleUrls: ['./databaseAddMain.component.css']
})
export class DatabaseAddMainComponent implements OnInit {

  typeNormalizedDataList: NORMALIZED_LIST_TYPE = NORMALIZED_LIST_TYPE.DATABASE

  constructor(private intercom: IntecomService) { }

  ngOnInit() {
  }

}
