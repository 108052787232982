<mat-card class="distanza">
  <mat-card-title>IMPORT EXPERIMENTAL DNA ANALYSIS </mat-card-title>
  <mat-card-content>
    <div class="forminput">
      <mat-label>Upload DNA Analysis File</mat-label><br />
      <br />

      <div class="row_file distancetop">
        <div class="column_half">
          <input
            type="file"
            (change)="getFile($event)"
            id="filesdna"
            #filesdna
          />
        </div>
        <div class="column_half">
          <button
            *ngIf="!_show_first_result"
            mat-stroked-button
            color="warn"
            class="distanza2"
            (click)="clear()"
          >
            <mat-icon>clear</mat-icon>
            CLEAR
          </button>
        </div>
      </div>

      <br />
    </div>
    <mat-card-title *ngIf="!_show_first_result"
      >DIFFERENT SAMPLE FILE NAME FOUND</mat-card-title
    >
    <br />

    <div class="row_file distancetop" [hidden]="_show_first_result">
        <div class="column_half">

        </div>
        <div class="column_half">
          <button
            *ngIf="!_show_first_result"
            mat-raised-button
            color="warn"
            class="distanza2"
            (click)="bulkImport()"
          >
             <mat-icon>save</mat-icon>
            IMPORT SELECTED
          </button>
        </div>
      </div>
    <table
      [hidden]="_show_first_result"
      mat-table
      [dataSource]="data | keyvalue"
      class="tabella"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.value.filename }}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element">{{ element.value.category }}</td>
      </ng-container>

      <ng-container matColumnDef="import">
        <th mat-header-cell *matHeaderCellDef>Single Import</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="!element.value.category"
            mat-mini-fab
            color
            color="warn"
            class="distanza5"
            (click)="saveRaw(element.key)"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button
            *ngIf="element.value.category"
            mat-mini-fab
            color
            disabled
            class="distanza5"
          >
            <mat-icon>save</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="graph">
        <th mat-header-cell *matHeaderCellDef>Preview Graph</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-mini-fab
            color
            color="primary"
            class="distanza5"
            (click)="showgraph(element.key)"
          >
            <mat-icon> bar_chart</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >


          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element.value) : null"
            [checked]="selection.isSelected(element.value)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>
