import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { iNormalizedDataDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { i_Manufacturer } from '../models/Manufacturer';
import { KcConnectService } from '../providers/kc-connect.service';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { Logger, LogLevel } from 'ask-logger';
import { CategoryService } from '../providers/Category.service';
import { iCategoryOut } from 's3qr-dnadto/src/dto/CategoryDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { resourceLimits } from 'worker_threads';
const LOGGER = Logger.getLogger('NormalizedDataSaveFormComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-normalizedDataSaveForm',
  templateUrl: './normalizedDataSaveForm.component.html',
  styleUrls: ['./normalizedDataSaveForm.component.scss']
})
export class NormalizedDataSaveFormComponent implements OnInit {
  myControl = new FormControl();
  registerForm: FormGroup;
  normalizedData: iNormalizedDataDTO;
  options: string[] = [];
  reliability : number = 100

  filteredOptions: Observable<string[]>;
  selected: string;
  categories = new Map<string, iCategoryOut>();


  constructor(protected keycloakService: KeycloakService, private normalizedDataService: NormalizedDataService,
    private categoryService: CategoryService,
    public dialogRef: MatDialogRef<NormalizedDataSaveFormComponent>,
    private _snackBar: MatSnackBar,
    protected comunication: KcConnectService, @Inject(MAT_DIALOG_DATA) public data: iNormalizedDataDTO) {
    this.normalizedData = this.data;
    LOGGER.info("Arrivato", this.normalizedData)


    this.normalizedData.reliability
    this.categoryService.getCategories().then((cat: iCategoryOut[]) => {

      cat.forEach(x => {
        const key: string = x.name + "@" + x.type
        this.options.push(key)
        this.categories.set(key, x)
      })

    }).catch(e => {
      LOGGER.error(e)
      this._snackBar.open("Error retrieving categories", "Ok");
    })


    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngOnInit() {



  }
  saveNormalized() {
    LOGGER.info(this.normalizedData);

    if (this.reliability) {
      if ((this.reliability > 100) || (this.reliability <= 0)) {
        this._snackBar.open("Reliability must be between 1 and 100", "Ok");
      } else {

        if (this.selected) {

          if (this.categories.has(this.selected)) {
            this.normalizedData.category = this.categories.get(this.selected).name

            this.normalizedData.reliability = this.reliability / 100;
            this.normalizedDataService.postNormalizedData(this.data).then((reult: iNormalizedDataDTO) => {
              LOGGER.info(reult)
              this.normalizedDataService.putNormalizedDataElements(reult.id, this.normalizedData.normalizedDataElements).then(result2 => {
                LOGGER.info(result2)
                this.dialogRef.close(true);
              }).catch(e => {
                LOGGER.error(e)
              })
            }).catch(e => {
              LOGGER.error(e)
            })
            // LOGGER.info("TO SEND", this.normalizedData)
            // this.normalizedDataService.saveNormalized(this.normalizedData).then(result => {
            //   LOGGER.info("ARRIVED", result)
            // })


          } else {
            this._snackBar.open("Please Select an existing Category", "Ok");
          }
        } else {
          this._snackBar.open("Please Select a Category", "Ok");
        }
      }
    } else {
      this._snackBar.open("Please Select a Reliability", "Ok");
    }





  }
  close() {
    this.dialogRef.close(false);
  }
}
