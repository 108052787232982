<mat-card class="distanza">


  <div id="comp-render" *ngIf="!show_graph">
     <mat-card-title>SEARCH FOR A RAW DATA FROM DATABASE</mat-card-title>
  <app-rawDataFinder2></app-rawDataFinder2>
  <br />
    <mat-card-subtitle>SEARCH RESULTS</mat-card-subtitle>
    <app-rawDataList></app-rawDataList>
    <br />
    <mat-card-subtitle>NEW RAW DATA</mat-card-subtitle>
    <app-rawDataList [modeSelect]="true"></app-rawDataList>
  </div>






  <div id="comp-render" *ngIf="show_graph">
    <div class="column_scxg">
      <div class="row">
        <div class="column">

        </div>
        <div class="column">
          <button
            class="bottone"
            mat-raised-button

            (click)="back()"
            aria-label="Back"
          > Back to search
           <mat-icon>backspace</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <mat-card-title>NORMALIZED DATA FOR : {{ selected }}</mat-card-title>
    <app-dnagraph></app-dnagraph>
    <br />
    <br />
    <mat-card-title>NORMALIZED DATA EDITOR : </mat-card-title>
    <br />
    <app-normalizedDataEditor></app-normalizedDataEditor>
  </div>
</mat-card>
