
<table mat-table [dataSource]="databases" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef>Author</th>
    <td mat-cell *matCellDef="let element">{{ element.author }}</td>
  </ng-container>
  <ng-container matColumnDef="creation">
    <th mat-header-cell *matHeaderCellDef>Creation</th>
    <td mat-cell *matCellDef="let element">
      {{ element.creationDate | date: "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <!-- Remove Column -->
  <ng-container matColumnDef="desc">
    <th mat-header-cell *matHeaderCellDef>Desc</th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-mini-fab
        (click)="show(element)"
        color="primary"
        aria-label="Desc Element"
      >
        <mat-icon>info</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="info">
    <th mat-header-cell *matHeaderCellDef>Details</th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-mini-fab
        (click)="info(element)"
        color="primary"
        aria-label="Desc Element"
      >
        <mat-icon>details</mat-icon>
      </button>
    </td>
  </ng-container>
 <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef> Select </th>
    <td mat-cell *matCellDef="let element">
           <mat-radio-button (click)="$event.stopPropagation()"
                                      (change)="select(element)"
name="B"
                                      ></mat-radio-button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
