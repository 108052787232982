<mat-card class="distanza">
  <mat-card-title
    >RAW DATA IDENTIFICATION : {{ rawDataFileName }}</mat-card-title
  >

  <div class="row">
    <div class="column_sx">
      <mat-label>SELECT A CATEGORY</mat-label>
       <mat-form-field class="formfield" appearance="fill">
      <mat-select [(value)]="selected_category">
        <mat-option *ngFor="let category of categories" [value]="category">
          {{ category.type }} - {{ category.name }}
        </mat-option>
      </mat-select>
      </mat-form-field>
    </div>
    <div class="column_dx">
      <button
        class="bottone"
        mat-raised-button
        color="warn"
        (click)="saveRawData()"
      >
        Save
      </button>
    </div>
  </div>
</mat-card>
