 <mat-card class="distanza">
   <app-normalizedDataFinder></app-normalizedDataFinder>
   <br>

    <app-databaseForm></app-databaseForm>

   <br>

   <mat-card-title>SELECT A NORMALIZED DATA FOR DATABASE</mat-card-title>
   <app-normalizedDataList [modeSelect]="typeNormalizedDataList"></app-normalizedDataList>
 </mat-card>
