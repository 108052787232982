import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})


export class MainComponent extends logged implements OnInit {


  result: any = {};
  env: any;
  _opnelots: boolean = false;
  _archive: boolean = false;
  _dna: boolean = false;
  _patogeni: boolean = false;
  _raw: boolean = false;
  _norm: boolean = false;
  _database: boolean = false;
  _databaselist: boolean = false;
  _investigation: boolean = false;
  _categoryList: boolean = false;
  _info: boolean = false;


  writeOrDie(text: any) {

      console.log('Error :', text);

  }


  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService, private _snackBar: MatSnackBar) {
    super(keycloakService, comunication);
    this.env = environment;

  }

  ngOnInit(): void {


  }

  profile() {
    window.open(environment.manage_user_url);
  }

  goToOpenLots() {

    console.log("toddddken : ", this.token.user)
    this._opnelots = true;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }

  goToArchive() {
    this._opnelots = false;
    this._archive = true;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }
  goToDNA() {
    this._opnelots = false;
    this._archive = false;
    this._dna = true;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }
  goToPatogeni() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = true;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }
  goToRaw() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = true;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }
  goToNorm() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = true;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }

  goToDatabase() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = true;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }
  goToDatabaseList() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = true;
    this._investigation = false;
    this._categoryList = false;
    this._info = false;
  }
  goToInvestigate() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = true;
    this._categoryList = false; this._info = false;
  }
  goToCategoryList() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = true;
    this._info = false;
  }

  goToInfo() {
    this._opnelots = false;
    this._archive = false;
    this._dna = false;
    this._patogeni = false;
    this._raw = false;
    this._norm = false;
    this._database = false;
    this._databaselist = false;
    this._investigation = false;
    this._categoryList = false;
    this._info = true;
  }
  error() {
    this._snackBar.open("Not Implemented Yet", "Ok");
  }

logout() {
  this.keycloakService.logout();
}

}

