<mat-card class="distanza" *ngIf="!_show_result">
  <div class="row">
    <div class="column">
      <mat-form-field appearance="fill" class="requests">
        <mat-label>Select Minimum number of Peaks to Compare Numbers</mat-label>
        <input
          matInput
          class="requests"
          type="number"
          [(ngModel)]="peak_number"
          min="1"
          max="100"
        />
      </mat-form-field>
    </div>
    <div class="column">
      <mat-form-field appearance="fill" class="requests">
        <mat-label>Select Normalized Data To Classify</mat-label>
        <input matInput type="number" [(ngModel)]="k" min="1" max="100" class="requests" />
      </mat-form-field>
    </div>
     <div class="column">
      <mat-form-field appearance="fill" class="requests">
        <mat-label>P Value (1-7max)</mat-label>
        <input matInput type="number" [(ngModel)]="p" min="1" max="7" class="requests" />
      </mat-form-field>
    </div>
    <div class="column_int">
      <button class="bottone" (click)="go()" mat-raised-button color="warn">
        <mat-icon>flash_on</mat-icon>Analyse!
      </button>
    </div>
  </div>
</mat-card>
<mat-card class="distanza" *ngIf="!_show_result" >
  <mat-card-title>SELECT A DATABASE</mat-card-title>
  <app-databaseFinder></app-databaseFinder>
  <br />
  <mat-card-subtitle>Databases</mat-card-subtitle>
  <app-databaseList [modeSelect]="typeDatabaseList"></app-databaseList>
</mat-card>
<mat-card class="distanza" *ngIf="!_show_result" >
  <mat-card-title>SELECT A NORMALIZED DATA TO INVESTIGATE</mat-card-title>
  <app-normalizedDataFinder></app-normalizedDataFinder>
  <br />
  <mat-card-subtitle>Normalized Datas</mat-card-subtitle>
  <app-normalizedDataList
    [modeSelect]="typeNormalizedDataList"
  ></app-normalizedDataList>
</mat-card>
<mat-card class="distanza" *ngIf="_show_result" >
   <div class="row">
        <div class="column_back">

        </div>
        <div class="column_back">
          <button
            class="bottone"
            mat-raised-button

            (click)="back()"
            aria-label="Back"
          > Back
           <mat-icon>backspace</mat-icon>
          </button>
        </div>
      </div>
      <app-InvestigationResultTab></app-InvestigationResultTab>

</mat-card>
