import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';
import { IntecomService } from '../providers/intecom.service';
import { Subscription, zip } from 'rxjs';
import { i_Lot4Analysis, Lot4Analysis } from '../models/Lot4Analysis';
import { LotProviderService } from '../providers/LotProvider.service';
import { ShowimageComponent } from '../showimage/showimage.component';
import { MyWebToken } from '../models/webtoken';

import { showDescriptionComponent } from '../showDescription/showDescription.component';
import { Generic_messageComponent } from '../generic_message/generic_message.component';
import { i_Generic_Report } from '../../../../s3qr-backend/models/Generic_Report';
@Component({
  selector: 'app-lotcompletedview',
  templateUrl: './lotcompletedview.component.html',
  styleUrls: ['./lotcompletedview.component.css']
})
export class LotcompletedviewComponent extends logged  implements OnInit {

  @Input()
  token: MyWebToken;

  subscription: Subscription;
  env: any;
  lots: Lot4Analysis[] = [];
  lotid: string;

  showeditor = false;

  constructor(public dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,

    protected lot_service: LotProviderService, private intercom: IntecomService) {
    super(keycloakService, comunication);
    this.env = environment;

  }
  ngOnInit() {
    console.log("Mi tiro su!")
    this.populatelist();
    this.subscription = this.intercom.refreshitem$.subscribe((message) => {
      this.showeditor = false;
      this.populatelist();
    });
    console.log("----------------------------")
    console.log(this.token)
    console.log("----------------------------")
  }
  deletelot(lot: Lot4Analysis) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;

    let _message: string = "Would you like to delete this analysis ? "

    dialogConfig.data = { title: "Analysis for  " + lot._id + "", message: _message }
    const dialogRef2 = this.dialog.open(Generic_messageComponent, dialogConfig);
    console.log("Lot to delete : ", lot._id)
    dialogRef2.afterClosed().subscribe(result => {
      console.log("[deletelot] The dialog was closed", result);

      if (result) {

        // this.lots.find(x => x._id == lot._id)?.generic_Reports.filter(z => z.lab_id != this.token.user.lab);
        for (let x of this.lots) {
          if (x._id == lot._id) {
            x.generic_Reports = x.generic_Reports.filter(z => z.lab_id != this.token.user.lab);
            break;
          }
        }


        //   lot.rate_enabled = !lot.rate_enabled;
           this.lot_service.updateLot4Analysis(lot).then((esito) => {
            if (esito) {
              this.populatelist();
            } else {
              console.log ("[deletelot] ", esito)
            }
          }).catch(e => {
            console.log ("[deletelot] ", e)
           })
      }

    });

  }

  ngOnDestroy() {
    console.log("Mi spengo")
    this.subscription.unsubscribe()
  }
  populatelist() {

    console.log("--------------------------")
    console.log(this.token)
    console.log("--------------------------")
    this.lot_service.getCompletedLot4Analysis(this.token.user.lab).then((value: any) => {
      console.log("SUCCESSO: ", value)
      value = value as i_Lot4Analysis[];
      this.lots = [];
      value.forEach(element => {
        this.lots.push(Lot4Analysis.of(element))
      });
      console.log("lots", this.lots)

    }).catch((e) => {
      console.log("ERROR: ", e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }

  testQR(id: string) {
    // let dialogConfig = new MatDialogConfig();
    // dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 400;
    // dialogConfig.data = "https://s3qr.it/#/" + id
    // this.dialog.open(QrcodegeneratorComponent, dialogConfig);

  }

  addAnalysis(lot: i_Lot4Analysis) {
    this.showeditor = false;
    this.intercom.refreshForm(lot);
    this.showeditor = true;
  }

  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }

  showdesc(desc: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = desc
    this.dialog.open(showDescriptionComponent, dialogConfig);
  }
  getStatus(id: string) {
    console.log("not  implemented yet")

  }

}
