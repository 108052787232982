<div class="forminput">
  <mat-label>Upload/change the file of data (csv format) result of the analysis</mat-label><br />
  <br />
  <mat-label>Select a filter :</mat-label><br />
  <mat-radio-button value="115" checked="true"> Animals </mat-radio-button>
  <mat-radio-button value="290" class="distanceleft" checked="false">
    Vegetables
  </mat-radio-button>

  <div class="row distancetop">
    <input
      type="file"
      (change)="getFileGrafico($event)"
      id="filesdna"
      #filesdna
    />
  </div>


  <br />
  <button mat-stroked-button  color="warn"  [hidden]="!isThereAGraph" (click)="clearGraph($event); false" >
    Remove the Graph
  </button>
  <!-- <canvas #lineCanvas></canvas> -->
  <div id="barChart3"></div>
  <!--<svg width="960" height="500"></svg>-->
</div>
