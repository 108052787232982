  <BR>
  <mat-card-title>GRAPH RESULT: </mat-card-title>
  <mat-card-subtitle><B>Gini Index : {{gini}}</B></mat-card-subtitle>

  <div class="row">
    <div class="column_s">

    </div>
    <div class="column_d">
      <button
        class="bottone"
        mat-raised-button
        color="warn"
        (click)="exportPDF()"
      ><mat-icon>picture_as_pdf</mat-icon>

      </button>
    </div>
        <div class="column_d">
      <button
        class="bottone"
        mat-raised-button
        color="warn"
        (click)="save()"
      >
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

    <!-- <canvas #lineCanvas></canvas> -->
    <div class="altezza" >
    <div id="barChart3"></div>
    </div>
    <!--<svg id="barChart4" width="960" height="500"></svg>-->

