import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';
import { IntecomService } from '../providers/intecom.service';
import { Subscription } from 'rxjs';
import { i_Lot4Analysis, Lot4Analysis } from '../models/Lot4Analysis';
import { LotProviderService } from '../providers/LotProvider.service';
import { ShowimageComponent } from '../showimage/showimage.component';
import { MyWebToken } from '../models/webtoken';
import { showDescriptionComponent } from '../showDescription/showDescription.component';


@Component({
  selector: 'app-lotview',
  templateUrl: './lotview.component.html',
  styleUrls: ['./lotview.component.css']
})
export class LotviewComponent  implements OnInit {

  @Input()
  token: MyWebToken;

  subscription: Subscription;
  env: any;
  lots: Lot4Analysis[] = [];
  lotid: string;

  showeditor = false;

  constructor(public dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected lot_service: LotProviderService, private intercom: IntecomService) {
    // super(keycloakService, comunication);
    this.env = environment;

  }
  ngOnInit() {
    console.log("Mi tiro su!")
    this.populatelist();
    this.subscription = this.intercom.refreshitem$.subscribe((message) => {

      console.log("arrivato :", message)
      this.showeditor = false;
      this.populatelist();
    });
  }


  ngOnDestroy() {
    console.log("Mi spengo")
    this.subscription.unsubscribe()
  }
  populatelist() {

    console.log("--------------------------")
    console.log(this.token)
    console.log("--------------------------")
    this.lot_service.getPendingLot4Analysis(this.token.user.lab).then((value: any) => {
      console.log("SUCCESSO: ", value)
      value = value as i_Lot4Analysis[];
      this.lots = [];
      value.forEach(element => {
        this.lots.push(Lot4Analysis.of(element))
      });
      console.log("lots", this.lots)

    }).catch((e) => {
      console.log("ERROR: ", e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }

  testQR(id: string) {
    // let dialogConfig = new MatDialogConfig();
    // dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 400;
    // dialogConfig.data = "https://s3qr.it/#/" + id
    // this.dialog.open(QrcodegeneratorComponent, dialogConfig);

  }

  addAnalysis(lot : i_Lot4Analysis) {
    this.showeditor = false;
    this.intercom.refreshForm(lot);
    this.showeditor = true;
    console.log("Cientro")
    // this.subscription.unsubscribe()

  }

  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }

  showdesc(desc: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = desc
    this.dialog.open(showDescriptionComponent, dialogConfig);
  }
  getStatus(id: string) {
    console.log("not  implemented yet")

  }

}
