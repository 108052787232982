import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ispaform',
  templateUrl: './ispaform.component.html',
  styleUrls: ['./ispaform.component.css']
})
export class IspaformComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getFileGrafico($event) {



    let file: File = $event.target.files[0];



  }

}
