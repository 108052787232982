import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Logger, LogLevel } from 'ask-logger';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { IntecomService } from '../providers/intecom.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


import { Subscription } from 'rxjs';
import { NormalizedGraphShowComponent } from '../normalizedGraphShow/normalizedGraphShow.component';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { NORMALIZED_LIST_TYPE } from '../models/ServiceEnum';
import { RawDataService } from '../providers/RawData.service';

import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { RawGraph } from '../models/RawGraph';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
const LOGGER = Logger.getLogger('RawDataListComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-rawDataList',
  templateUrl: './rawDataList.component.html',
  styleUrls: ['./rawDataList.component.css']
})
export class RawDataListComponent implements OnInit {

  @Input()
  modeSelect: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  subscription_ToShowNormalizedData: Subscription;

  rawData: MatTableDataSource<iRawDataDTOOUT>;
  displayedColumns: string[] = ['code', 'author', 'creationDate', 'category', 'rawdatas_number', 'graph' ];

  selected: iNormalizedDataLISTDTO;

  constructor(private nDataService: RawDataService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) {

  }

  ngOnInit() {
    LOGGER.info("this.modeSelect => " + this.modeSelect)
    if (this.modeSelect) {
      LOGGER.info("Sono nella modalità new")
      this.nDataService.getAllRawData().then((response: iRawDataDTOOUT[]) => {
        if (response) {
          response.sort((a, b) => {

            const ad = new Date(a.creationDate);
            const bd = new Date(b.creationDate);
            return bd.getTime() - ad.getTime()
          });
          // this.rawData = [];
          LOGGER.info("response.length", response.length)
          this.rawData = new MatTableDataSource<iRawDataDTOOUT>(response.filter(x => x.normalizedDatas_number == 0))
          this.rawData.paginator = this.paginator;
          this.rawData.sort = this.sort;
          // LOGGER.info("(this.rawData.length", this.rawData.s)

        }

      }).catch((error) => {
        // this._snackBar.open("Error retrinving data", );
        LOGGER.info(error)
      })
    } else {
      this.subscription_ToShowNormalizedData = this.intercom.rawDataToShow$.subscribe((message: string[]) => {

        LOGGER.info("Message arrived :", message)
        if (message) {
          this.nDataService.getAllRawData().then((response: iRawDataDTOOUT[]) => {
            if (response) {
              this.rawData = new MatTableDataSource < iRawDataDTOOUT >();
              response.forEach(x => {
                if (message.indexOf(x.id) >= 0) {
                  this.rawData.data.push(x)
                }
              });
              this.rawData.paginator = this.paginator;
              this.rawData.sort = this.sort;
            }
          }).catch((error) => {
            // this._snackBar.open("Error retrinving data", );
            LOGGER.error(error)
          })
        }

      })
    }

  }

  graph(toGraph: iRawDataDTOOUT) {
    LOGGER.info("[showgraph]", toGraph);
    this.intercom.dnaAnalysisRefrehForm(toGraph)

  }



}
