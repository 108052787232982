<BR>
  <mat-card-title>RESULTS: </mat-card-title>
  <mat-card-subtitle>Bold value is the k value</mat-card-subtitle>
  <mat-card-subtitle><B>Gini Index : {{gini}}</B></mat-card-subtitle>


  <mat-card


    *ngFor="let ct of cat_results; let i=index"
    [ngClass]="setBackground(i)"
  >
    <mat-card-subtitle><h2>{{ ct.name }}</h2></mat-card-subtitle>
    <div class="row">
      <div class="column">
        AVERAGE : {{ct.average | number : "1.0-2"}}
      </div>
      <div class="column">
        VARIANCE : {{ct.variance | number : "1.0-2"}}
      </div>
      <div class="column">
        MAX DISTANCE : {{ct.max | number : "1.0-2"}}
      </div>
      <div class="column">
        MIN DISTANCE: {{ct.min |number : "1.0-2"}}
      </div>

    </div>
    <div class="row">
      <div class="column">
        <B>POSSIBILITY : {{ct.prob}} % </B>
      </div>
      <div class="column">

      </div>
      <div class="column">
        MAX SIMILARITY : {{ct.max_similarity|number : "1.0-2"}}
      </div>
      <div class="column">
        MIN SIMILARITY : {{ct.min_similarity|number : "1.0-2"}}
      </div>

    </div>



    <table mat-table [dataSource]="ct.results" [ngClass]="setBackgroundTable(i)">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
      </ng-container>
      <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef>Distance^p</th>
        <td mat-cell *matCellDef="let element">
          <p *ngIf="!element.is_k">{{ element.distance |number : "1.0-2"}}</p>
          <p *ngIf="element.is_k"><b>{{ element.distance |number : "1.0-2" }}</b></p></td>
      </ng-container>

      <ng-container matColumnDef="sim">
        <th mat-header-cell *matHeaderCellDef>Similarity</th>
        <td mat-cell *matCellDef="let element">
          <p *ngIf="!element.is_k_sim">{{ element.similarity|number : "1.0-2" }}</p>
          <p *ngIf="element.is_k_sim"><b>{{ element.similarity|number : "1.0-2" }}</b></p></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card>
