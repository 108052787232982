import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { iNormalizedDataDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { iNormalizedDataElementDTO } from 's3qr-dnadto/src/dto/NormalizedDataElementDTO';


import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NormalizedDataService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }


  public async postNormalizedData(towrite: iNormalizedDataDTO): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.post("http://127.0.0.1:5022/normalizedDatas", towrite).toPromise();
    return this.http.post(environment.main_url + environment.backend_dna_NormalizedDatas, towrite).toPromise();

    //
  }

  public async saveNormalized(towrite: iNormalizedDataDTO): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.post(environment.main_url + "http://127.0.0.1:5030/generic_parse", towrite).toPromise();

    //
  }



  public async putNormalizedDataElements(id : string , towrite: iNormalizedDataElementDTO []): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.put("http://127.0.0.1:5022/normalizedData/" + id + "/normalizedDataElements", towrite).toPromise();
    return this.http.put(environment.main_url + environment.backend_dna_normalizedData + "/"+ id + environment.backend_dna_normalizedDataElements, towrite).toPromise();

    //
  }


  public async getNormalizedData(): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.get("http://127.0.0.1:5022/normalizedDatas").toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_NormalizedDatas).toPromise();

    //
  }

  public async getNormalizedDataById(id : string ): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.get("http://127.0.0.1:5022/normalizedData/" + id).toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_normalizedData +"/" + id).toPromise();

    //
  }


}
