import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos

export const environment = {

  production: false,
  envName: 'local',
  keycloak: {
    issuer: 'https://auth.s3qr.it/auth',
    realm: 'S3qr',
    requireHttps: true,
    clientId: 's3qr-lab'

  },
  // main_url: 'https://www.fhffcapp.it/s3edge/',
  timeout: 10000,
  // main_url: 'https://www.fhffcapp.it/s3edgemanager',
  main_url: 'https://www.fhffcapp.it/s3edgemanager',
  title: "S3QR.IT LAB MODULE",
  bottom: "© Work in progress CNR-IREA 2020 - v.0.10.1C",
  manage_user_url: "https://auth.s3qr.it/auth/realms/S3qr/account/applications",
  getLot4Analysis: "/getLot4Analysis",
  backend_getGenericRepoByLot: "/getGenericRepoByLot/",
  backend_getGenericRepoByLab: "/getGenericRepoByLab/",
  backend_addGenericRepo: "/addGenericRepo",
  backend_getLot4AnalysisByLab: "/getLot4AnalysisByLab/",
  success: "Success!",
  error: "Error!",
  warning: "Warning!",
  backend_parser: "/parse",
  refreshlablist: "REFRESH_LAB_LIST",
  backend_getPendingLot4AnalysisByLab: "/getPendingLot4AnalysisByLab/",
  backend_getCompletedLot4AnalysisByLab: "/getCompleteLot4AnalysisByLab/",
  update_Lot: "/addGenericRepo",
  reference_graph : ["IPC","RP"],
  dnaEnable: ['CNR-IREA','CNR-IBBA'],
  patogeniEnable: ['IBBA_Lodi'],
  //===========================DNA
  backend_dna_categories: "/categories",
  backend_dna_category_id: "/category/:id",
  backend_dna_category: "/category",
  backend_dna_rawDatas: "/rawDatas",
  backend_dna_rawData_id: "/rawData/:id",
  backend_dna_rawData: "/rawData",
  backend_dna_rawData_id_rawDataElements: "/rawData/:id/rawDataElements",
  backend_dna_rawDataElements: "/rawDataElements",
  backend_dna_dnadatabases: "/dnadatabases",
  backend_dna_dnadatabase_id: "/dnadatabase/:id",
  backend_dna_dnadatabase: "/dnadatabase",
  backend_dna_dnadatabase_id_NormalizedDatas: "/dnadatabase/:id/NormalizedDatas",
  backend_dna_NormalizedDatas: "/NormalizedDatas",
  backend_dna_dnadatabase_id_NormalizedDatas_list: "/dnadatabase/:id/NormalizedDatas/list",
  backend_dna_NormalizedDatas_list: "/NormalizedDatas/list",
  backend_dna_dnadatabase_id_NormalizedDatas_full: "/dnadatabase/:id/NormalizedDatas/full",
  backend_dna_NormalizedDatas_full: "/NormalizedDatas/full",
  backend_dna_normalizedDatas: "/normalizedDatas",
  backend_dna_normalizedData_id: "/normalizedData/:id",
  backend_dna_normalizedData_id_normalizedDataElements: "/normalizedData/:id/normalizedDataElements",
  backend_dna_normalizedData: "/normalizedData",
  backend_dna_normalizedDataElements: "/normalizedDataElements",
  backend_dna_doAnalysis: "/doAnalysis",
  backend_dna_ResultAnalysis: "/ResultAnalysis",
  backend_dna_ResultAnalysis_id: "/ResultAnalysis/:id"

};
