<mat-card class="distanza">
  <mat-card-title>INSERT NEW NORMALIZED DATA ELEMENT: </mat-card-title>
  <mat-card-content>
    <form [formGroup]="registerForm" (ngSubmit)="addNormalized()">
      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Dye Peak</mat-label>
            <input
              matInput
              formControlName="dye_Peak"
              [(ngModel)]="data.dye_Peak"
              placeholder="Dye Peak"
            />
             <div *ngIf="submitted && f.dye_Peak.errors" class="invalid-feedback">
                        <div *ngIf="f.dye_Peak.errors.required">Dye Peak is required</div>
                    </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Area</mat-label>
            <input
              matInput
              type="number"
              formControlName="area"
              [(ngModel)]="data.area"
              placeholder="Area"
            />
             <div *ngIf="submitted && f.area.errors" class="invalid-feedback">
                        <div *ngIf="f.area.errors.required">AreaD is required</div>
                    </div>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Size</mat-label>
            <input
              matInput
              type="number"
              formControlName="size"
              [(ngModel)]="data.size"
              placeholder="Size"
            />
            <div *ngIf="submitted && f.size.errors" class="invalid-feedback">
               <div *ngIf="f.size.errors.required">Size is required</div>
            </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Height</mat-label>
            <input
              matInput
              type="number"
              formControlName="height"
              [(ngModel)]="data.height"
              placeholder="Height"
            />
            <div *ngIf="submitted && f.height.errors" class="invalid-feedback">
               <div *ngIf="f.height.errors.required">Height is required</div>
            </div>
          </mat-form-field>
        </div>
      </div>


      <button   mat-raised-button color="warn" class="distanza2">Add Row</button>

    </form>
  </mat-card-content>
  <!-- <button mat-raised-button class="distanza2" (click)="test()">
            TEST
          </button> -->
</mat-card>
