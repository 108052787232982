import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Logger, LogLevel } from 'ask-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { iCategoryOut } from 's3qr-dnadto/src/dto/CategoryDTO';
import { CategoryService } from '../providers/Category.service';
import { IntecomService } from '../providers/intecom.service';
const LOGGER = Logger.getLogger('CategoryListComponent')
LOGGER.set_level(LogLevel.DEBUG)

@Component({
  selector: 'app-categoryList',
  templateUrl: './categoryList.component.html',
  styleUrls: ['./categoryList.component.css']
})
export class CategoryListComponent implements OnInit {
  subscription_addCategory : Subscription;


  categories: iCategoryOut[];
  displayedColumns: string[] = ['name', 'type', 'creationDate', 'author' ];

  constructor(private intercom: IntecomService,private categoryService: CategoryService, private _snackBar: MatSnackBar) { }

  ngOnInit() {

    this.categoryService.getCategories().then((result: iCategoryOut[]) => {
      LOGGER.info("Query Result arrived :", result)
      this.categories = result;

    }).catch(err => {
      LOGGER.error(err)
    });
    // try {
    //   this.subscription_addCategory = this.intercom.categoryAdded$.subscribe((toAdd: iCategoryOut) => {
    //     LOGGER.info("Category is arrived :", toAdd)
    //     if (this.categories) this.categories.push(toAdd)
    //   });
    // } catch (error) {
    //   LOGGER.error(error)
    // }
    this.subscription_addCategory = this.intercom.categoryAdded$.subscribe((toAdd: iCategoryOut) => {
      LOGGER.info("Category is arrived :", toAdd)
      if (toAdd) {
        this.categoryService.getCategories().then((result: iCategoryOut[]) => {
          LOGGER.info("Query Result arrived :", result)
          this.categories = result;

        }).catch(err => {
          LOGGER.error(err)
        });
      }

    });
  }


  edit(toEdit: iCategoryOut) {
    LOGGER.info("[edit]", toEdit);


  }

  ngOnDestroy(): void {
    this.subscription_addCategory.unsubscribe();
  }

}
