import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-generic_message',
  templateUrl: './generic_message.component.html',
  styleUrls: ['./generic_message.component.css']
})
export class Generic_messageComponent {

  modalTitle: string;
  modalMessage: string;
  modalType: ModalType = ModalType.INFO;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalTitle = data.title;
    this.modalMessage = data.message;
    this.modalType = data.type;

    console.log(data)
  }

}
export enum ModalType {
  INFO = 'info',
  WARN = 'warn'
}
