import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Logger, LogLevel } from 'ask-logger';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { NormalizedGraphShowComponent } from '../normalizedGraphShow/normalizedGraphShow.component';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { DatabaseService } from '../providers/Database.service';
import { outDNADatabaseDTO } from 's3qr-dnadto/src/dto/DNADatabaseDTO';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
const LOGGER = Logger.getLogger('NormalizedDataListDialogComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-normalizedDataListDialog',
  templateUrl: './normalizedDataListDialog.component.html',
  styleUrls: ['./normalizedDataListDialog.component.css']
})
export class NormalizedDataListDialogComponent implements OnInit {

  normalizedData: MatTableDataSource<iNormalizedDataLISTDTO> ;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private nDataService: DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: outDNADatabaseDTO,
    private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<NormalizedDataListDialogComponent>,
    public dialog: MatDialog) {
    LOGGER.info(this.data)

  }

  ngOnInit() {
    this.displayedColumns = ['code', 'category', 'filterH', 'filterS', 'author', 'desc', 'graph'];
    this.nDataService.getNormalizedDataList(this.data.id).then((result: iNormalizedDataLISTDTO[]) => {

      result.sort((a, b) => (a.category > b.category) ? 1 : -1)
      this.normalizedData = new MatTableDataSource<iNormalizedDataLISTDTO>(result);
      this.normalizedData.paginator = this.paginator;
      this.normalizedData.sort = this.sort;
    }).catch(e => {
      LOGGER.error(e)
    })
  }

  ngAfterViewInit() {
    this.normalizedData.paginator = this.paginator;
  }
  show(toShowDesc: iNormalizedDataLISTDTO) {
    LOGGER.info(toShowDesc.desc)
    this._snackBar.open(toShowDesc.desc, "ok");
  }
  add(toShowDesc: iNormalizedDataLISTDTO) {

  }
  graph(toGraph: iNormalizedDataLISTDTO) {
    LOGGER.info("[save]");

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 1024;

    dialogConfig.data = toGraph
    const dialogRef2 = this.dialog.open(NormalizedGraphShowComponent, dialogConfig);
    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this._snackBar.open("OK", "Ok");

      } else {
        LOGGER.info("non salvato")
      }


    });
  }



}
