import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { Generic_Report } from '../models/Generic_Report';
import { i_Lot4Analysis, Lot4Analysis } from '../models/Lot4Analysis';

@Injectable({
  providedIn: 'root'
})
export class Generic_ReportService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }

  // public async getGeneric_Reports(lab: string): Promise<any> {

  //   return this.http.get(environment.main_url + environment.backend_getLot4AnalysisByLab + lab).pipe(timeout(environment.timeout)).toPromise();

  // }


  public async addGeneric_Report(l: i_Lot4Analysis): Promise<any> {
    console.log(l)

    return this.http.post(environment.main_url + environment.backend_addGenericRepo, l).toPromise();

  }

  public async getReports(): Promise<any>  {

    const h = new HttpHeaders({ Authorization: `Bearer aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa` });
    const options = {
      headers: h,
      // to display the full response

    };
    let url: string = "http://127.0.0.1:8080/reports/?page=0&pageSize=10&shop=22802f80-08a4-4be3-a6b0-91ced88d443e";

    // let url: string = "https://test-contaflow-be-j2jneks7ja-ew.a.run.app/user"
    return this.http.get(url, options  ).toPromise();
  }

  public async postReports(): Promise<any> {

    const h = new HttpHeaders({
      Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjp7ImVtYWlsIjoibHVjYS5mcmlnZXJpb0BleHQubGVyb3ltZXJsaW4uaXQiLCJMREFQIjoiNDAxMDAxMDQiLCJtaXNzaW9uIjoiRElHSVRBTCBERVYgU1BFQ0lBTElTVCIsInN0b3JlTnVtYmVyIjoiOTkiLCJzdG9yZU5hbWUiOiJTRURFIiwiZGVwYXJ0bWVudCI6IkRESS1ESUdJVEFMIFRSSUJFIiwic3VybmFtZSI6IkZSSUdFUklPIiwibmFtZSI6Ikx1Y2EiLCJncm91cHMiOnsicGVvcGxlIjpbIklULVNJQi1TRURFIiwiSVQtU0lCLURESS1ESUdJVEFMIFRSSUJFIl0sImFwcGxpY2F0aW9uUm9sZSI6W251bGxdfX0sImV4cCI6MTYzMDExNDE3MCwiaXNzIjoiIiwiaWF0IjoxNjMwMDcwOTcwfQ.Kol9NK-TKyAG8WWHcIPzOUfGlkj-qq0z_bcfu6qvmuE"
   });

    const comando  = {
      kind: "free",
      applicant: "aaaaa",
      orderNumber: "aaaaa",
      object: "aaaaa",
      discordance_value: "12.3",
      recipient: "aaaaa",
      shop: "22802f80-08a4-4be3-a6b0-91ced88d443e",
      text: "aaaaa",
      attachments: [{
        id: "6ad4dec7-a9ee-42ba-98a8-75df9b503269"

      }],
    }
    const options = {
      headers: h,
      // to display the full response

    };
    let url: string = "http://127.0.0.1:8080/reports";

    // let url: string = "https://test-contaflow-be-j2jneks7ja-ew.a.run.app/user"
    return this.http.post(url, comando, options).toPromise();
  }



}
