import { Generic_Report, i_Generic_Report } from "./Generic_Report";
import { i_Lab, Lab } from "./Lab";
import { i_Manufacturer, Manufacturer } from "./Manufacturer";

export class Lot4Analysis implements i_Lot4Analysis {

  _id: string;

  lot: string;
  category: string;
  manufacturer: Manufacturer;
  lastmodified: Date;
  labs: i_Lab[] = []
  expiration_date: Date;
  creation_date: Date;
  desc: string;
  image: string;
  status: STATUS;
  average_rate: number;
  voters: number;
  rate_enabled: boolean;
  generic_Reports: i_Generic_Report[] = []


  constructor() {

  }

  getGeneric_Report_ByLAb(lab: string) : i_Generic_Report {
    return this.generic_Reports.find((x)=> x.lab_id == lab)
  }



  updateGeneric_Report_ByLab(lab: string, gr : Generic_Report) {
    this.generic_Reports = this.generic_Reports.filter((x) => x.lab_id != lab);
    this.generic_Reports.push(gr);
  }

  public validateState() {
    if (!this._id) throw "_ID is missing";


    if (!this.lot) throw "Lotto is missing";
    if (!this.category) throw "Category is missing";
    if (!this.manufacturer) throw "Manufacturer is missing";
  }

  public static instanceOfLottoAnalisi(object: any): object is i_Lot4Analysis {
    if (("_id" in object)


      && ("category" in object)
      && ("manufacturer" in object)
      && ("lot" in object)) {
      return true;
    } else {
      return false;
    }
  }

  public getLabList() {
    let exit : string = "";
    if (this.labs) {
      for (let i = 0; i < this.labs.length; i++) {
        if (i == 0) {
          exit = this.labs[i]._id;
        } else {
          exit = exit + ", " + this.labs[i]._id;
        }
      }
    }
    return exit;
  }


  public static of(o: any): Lot4Analysis {
    if (this.instanceOfLottoAnalisi(o)) {
      try {

        let a: i_Lot4Analysis = o as i_Lot4Analysis;
        let m: Manufacturer = Manufacturer.of(a.manufacturer);
        let _l: Lab[] = [];
        if ("labs" in a) {
          for (let _i_lab of a.labs) {
            let _lab = Lab.of(_i_lab);
            _l.push(_lab);
          }
        } else {
          console.log("-- NON C'È LABS")
        }
        let _generic_Reports: Generic_Report[] = [];
        if ("generic_Reports" in a) {
          for (let gp of a.generic_Reports) {
            let _gp = Generic_Report.of(gp)
            _generic_Reports.push(_gp)
          }
        }

        const exit = new Lot4Analysis();
        exit._id = o._id;
        exit.category = o.category;
        exit.creation_date = new Date(o.creation_date)
        exit.expiration_date = new Date(o.expiration_date)
        exit.desc = o.desc;
        exit.lot = o.lot;
        exit.manufacturer = Manufacturer.of(o.manufacturer);
        exit.labs = _l
        exit.image = o.image;
        exit.status = o.status
        exit.generic_Reports = _generic_Reports;
        exit.average_rate = o.average_rate
        exit.voters = o.voters
        exit.rate_enabled = o.rate_enabled
        return exit;

      } catch (e) {
        throw Error("[a] Can not parse! :" + JSON.stringify(o));
      }
    } else {
      throw Error("[b] Can not parse! :" + JSON.stringify(o));
    }

  }





}




export interface i_Lot4Analysis {

  _id: string;


  lot: string;
  category: string;
  manufacturer: i_Manufacturer;
  lastmodified: Date;
  labs: i_Lab[];
  expiration_date: Date;
  creation_date: Date;
  desc: string;
  image: string;
  status: STATUS;
  generic_Reports: i_Generic_Report[];
  average_rate: number;
  voters: number;
  rate_enabled: boolean;
}

export enum STATUS {
  OPEN,
  PUBLISHED,
  REMOVED,

}
