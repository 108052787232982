<mat-card class="distanza">
  <mat-card-title> Save this Normalized Data: </mat-card-title>
  <br />

  <div class="row">
    <mat-label class="scritte">Author : {{ data.author }} </mat-label>
  </div>

  <div class="row">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Digit or select code</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [(ngModel)]="selected"
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <br />
  <div class="row">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Title</mat-label>
      <input
        type="text"
        matInput
         [(ngModel)]="normalizedData.title"
      />
    </mat-form-field>
  </div>
  <br />
  <div class="row">
    <mat-form-field   appearance="fill">
       <mat-label>Select Reliability</mat-label>
     <input matInput type="number" [(ngModel)]="reliability"   min="1"
    max="100"  />
  </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="standard" class="forminput2">
     <mat-label class="scritte">Normalized Data Description</mat-label>
      <textarea
        rows="4"
        matInput
        [(ngModel)]="normalizedData.desc"
        placeholder="Normalized Data descripion, insert what you want."
      ></textarea>
    </mat-form-field>
  </div>

   <br />
  <div class="row">
    <div class="column">
      <button class="bottone" mat-raised-button (click)="close()">
        <mat-icon>close</mat-icon>Close
      </button>
    </div>
    <div class="bottone_dx">
      <button
        class="bottone"
        mat-raised-button
        color="warn"
        (click)="saveNormalized()"
      >
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>
</mat-card>
