import { Parser } from '@angular/compiler/src/ml_parser/parser';
import { Component, Input, OnInit } from '@angular/core';
import * as Excel from 'exceljs';
import { Workbook } from 'exceljs/excel'
import * as d3 from 'd3-selection';
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import * as d3Shape from 'd3-shape';
import * as d3_all from "d3";
import { environment } from '../../environments/environment';
import { ParserService } from '../providers/parser.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
const LOGGER = Logger.getLogger('PatogeniGraphComponent')
LOGGER.set_level(LogLevel.DEBUG)
const fileToArrayBuffer = require('file-to-array-buffer')
@Component({
  selector: 'app-patogeni-graph',
  templateUrl: './patogeni-graph.component.html',
  styleUrls: ['./patogeni-graph.component.css']
})

export class PatogeniGraphComponent implements OnInit {
  fontsize: string;
  isThereAGraph: boolean = false;

  @Input()
  _graphs: any;

  c3chart: any;
  width: number;
  height: number;
  margin: any;
  x: any;
  y: any;
  _x = new Set();
  _y = new Set();
  svg: any;
  g: any;
  line: any;
  graphs = new Map();

  // points: any[] = [];
  // points2: any[] = [];
  constructor(public parser: ParserService, private intercom: IntecomService ) {

    if (window.innerWidth < 600) {
      this.fontsize = "25px";
      this.margin = { top: 10, right: 10, bottom: 60, left: 90 };
    } else {
      this.fontsize = "10px";
      this.margin = { top: 10, right: 10, bottom: 40, left: 40 };
    }
    this.width = 900 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }

  ngOnInit(): void {

    if (this._graphs) {

        this.initAxis_fromPoints()
        this.initSvg()

        this.initAxis();
        this.drawAxis();
        this.drawBars();


    }
  }



  async getFile($event) {
    let file: File = $event.target.files[0];

    const workbook = new Excel.Workbook();
    this.getBase64(file).then(result => {
      console.log(result.toString().length);
      this.parser.sendDocument(result.toString()).then((value) => {
        LOGGER.info("[getFile] - Parsed Data is coming")
        console.log(value)


        for (let i = 1; i < value.graph.length; i++) {
          let e = value.graph[i];
          if (!environment.reference_graph.includes(e[3])) {
            if ( (e[5]) && (e[1] != NaN)) {
              // console.log(e[5],e[2],e[1]);
              this._x.add(+e[2])
              this._y.add(+e[5])
              if (this.graphs.has(e[1])) {

                let temp = this.graphs.get(e[1]);
                temp.push({ x: +e[2], y: +e[5] });
                // console.log("-",temp)
                this.graphs.set(+e[1], temp);

              } else {
                let temp: any[] = [];

                temp.push({ x: +e[2], y: +e[5] });
                this.graphs.set(e[1], temp);
                // console.log("+", temp)
                // console.log("+", this.graphs)
              }
            }
          }


        }
        this.graphs.delete(NaN)
        LOGGER.info("[getFile] Graoh:", this.graphs)
        LOGGER.info("[getFile] X:", this._x)
        LOGGER.info("[getFile] Y:", this._y)
        LOGGER.info("[getFile] Graoh value:", this.graphs.values())
        this._graphs = Array.from(this.graphs.values());
        this.initSvg()
        this.initAxis();
        this.drawAxis();
        this.drawBars();
        this.isThereAGraph = true;

        this.intercom.patogeniDataForm(this._graphs);


      }).catch((e) => {
        LOGGER.error("[getFile]1", e)
      })
    }).catch(err => {
      LOGGER.error("[getFile]2", err)
    })




  }

  clearGraph($event) {
    this._x = new Set();
    this._y = new Set();
    this.graphs = new Map();
    this.isThereAGraph = false;
    this.svg.remove();
    this.intercom.patogeniDataForm(null);
  }


  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }


  initSvg() {

    if (!this.isThereAGraph) {
      d3.selectAll("g > *").remove()

      if (!this.svg) {
        this.svg = d3.select("#barChart3")
          .append("svg")
          .attr("width", '100%')
          .attr("height", '100%')
          .attr('viewBox', '0 0 900 500').attr('class', 'chart');
      }

      this.g = this.svg.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
      this.isThereAGraph = false;
    } else {
      d3.selectAll("g > *").remove()
      this.g = this.svg.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    }



  }
  initAxis( ) {
    // this.x = d3Scale.scaleBand().rangeRound([0, this.width]).padding(0.1);
    let _xt : any[] = Array.from(this._x.values())
    let _yt: any[] = Array.from(this._y.values())
    this.x = d3Scale.scaleLinear().domain([0, d3Array.max(_xt)]).range([0, this.width]);
    this.y = d3Scale.scaleLinear().domain([d3Array.max(_yt), 0]).range([0, this.height]);
    LOGGER.info("[initAxis] X :",this.x)
    LOGGER.info("[initAxis] Y :",this.y)

  }

  initAxis_fromPoints() {

    for (let value of this._graphs) {

      for (let value2 of value) {

        LOGGER.info("[initAxis_fromPoints()] >>" + value2);
        this._x.add(+value2.x);
        this._y.add(+value2.y);
      }

    }
    LOGGER.info("[initAxis_fromPoints()] X :", this._x)
    LOGGER.info("[initAxis_fromPoints()] Y :", this._y)




  }

  drawAxis() {

    this.g.append("g")
      .attr("class", "axis axis--x")
      .style("font-size", this.fontsize)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3Axis.axisBottom(this.x));
    this.g.append("g")
      .style("font-size", this.fontsize)
      .attr("class", "axis axis--y")
      .call(d3Axis.axisLeft(this.y))
      .append("text")
      .attr("class", "axis-title")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("Frequency");
  }

  drawBars() {
    this.line = d3Shape.line()
      .x((d: any) => this.x(d.x))
      .y((d: any) => this.y(d.y)).curve(d3_all.curveMonotoneX);

    // // Configuring line path
    for (let value of this._graphs) {
      LOGGER.info("[drawBars] value :", value);
      this.svg.append('path')
        .datum(value)
        .attr("class", "line") // Assign a class for styling
        .attr("d", this.line).style('fill', 'none')
        .style('stroke', "#" +  Math.floor(Math.random() * 16777215).toString(16) )
        .style('stroke-width', '2px');; // 11. Calls the line generator

    }


  }
}
