import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { i_Lot4Analysis } from '../models/Lot4Analysis';
import { outDNADatabaseDTO } from 's3qr-dnadto/src/dto/DNADatabaseDTO';
import { iNormalizedDataDTO, iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { iCategoryOut } from 's3qr-dnadto/src/dto/CategoryDTO';
import { InvestigationResult } from '../models/InvestigationResult';

@Injectable({
  providedIn: 'root'
})
export class IntecomService {

  constructor() { }

  private _refresh_Form: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Message: BehaviorSubject<string> = new BehaviorSubject(null);
  private _dna_Message: BehaviorSubject<string> = new BehaviorSubject(null);
  private _patogeni_Message: BehaviorSubject<string> = new BehaviorSubject(null);
  private _dnaAnalysis_Message: BehaviorSubject<iRawDataDTOOUT> = new BehaviorSubject<iRawDataDTOOUT>(null);
  private _normalizedData_Message: BehaviorSubject<iNormalizedDataDTO> = new BehaviorSubject<iNormalizedDataDTO>(null);
  private _normalizedDataGraph_Message: BehaviorSubject<iNormalizedDataDTO> = new BehaviorSubject<iNormalizedDataDTO>(null);
  private _rawData_ToShow_Message: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private _normalizedData_ToShow_Message: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private _newDatabase: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _addNormalizedToDataBase: BehaviorSubject<iNormalizedDataLISTDTO> = new BehaviorSubject<iNormalizedDataLISTDTO>(null);
  private _databases_ToShow_Message: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private _databases_Selected_Message: BehaviorSubject<outDNADatabaseDTO> = new BehaviorSubject<outDNADatabaseDTO>(null);
  private _normalizedData_Selected_Message: BehaviorSubject<iNormalizedDataLISTDTO> = new BehaviorSubject<iNormalizedDataLISTDTO>(null);
  private _category_added_Message: BehaviorSubject<iCategoryOut> = new BehaviorSubject<iCategoryOut>(null);
  private _Investigation_result_arrived_Message: BehaviorSubject<InvestigationResult> = new BehaviorSubject<InvestigationResult>(null);



  formitem$ = this._refresh_Form.asObservable();
  refreshitem$ = this._refresh_Message.asObservable();
  dnaDataForm$ = this._dna_Message.asObservable();
  patogeniDataForm$ = this._patogeni_Message.asObservable();
  dnaAnalysisForm$ = this._dnaAnalysis_Message.asObservable();
  normalizedDataForm$ = this._normalizedData_Message.asObservable();
  normalizedDataFormGraph$ = this._normalizedDataGraph_Message.asObservable();
  normalizedDataToShow$ = this._normalizedData_ToShow_Message.asObservable();
  rawDataToShow$ = this._rawData_ToShow_Message.asObservable();
  newDatabase$ = this._newDatabase.asObservable();
  addNormalizedToDatabase$ = this._addNormalizedToDataBase.asObservable();
  databaseToShow$ = this._databases_ToShow_Message.asObservable();
  selectedDatabase$ = this._databases_Selected_Message.asObservable();
  selectedNormalized$ = this._normalizedData_Selected_Message.asObservable();
  categoryAdded$ = this._category_added_Message.asObservable();
  investigationResultArrived$ = this._Investigation_result_arrived_Message.asObservable();

  investigationResultArrived(message: InvestigationResult) {
    this._Investigation_result_arrived_Message.next(message)
  }

  refreshList(message: string) {
    this._refresh_Message.next(message);
  }

  refreshForm(lot : i_Lot4Analysis) {
    this._refresh_Form.next(JSON.stringify(lot));
  }

  dnaDataForm(data: any) {
    this._dna_Message.next(JSON.stringify(data));
  }

  patogeniDataForm(data: any) {
    this._patogeni_Message.next(JSON.stringify(data));
  }

  dnaAnalysisRefrehForm(data: iRawDataDTOOUT) {
    this._dnaAnalysis_Message.next(data);
  }

  refreshNormalizedData(data: iNormalizedDataDTO) {
    this._normalizedData_Message.next(data);
  }

  refreshNormalizedDataGraph(data: iNormalizedDataDTO) {
    this._normalizedDataGraph_Message.next(data);
  }

  refreshNormalizedDataToShow(data: string[]) {
    this._normalizedData_ToShow_Message.next(data);
  }

  refreshRawDataToShow(data: string[]) {
    this._rawData_ToShow_Message.next(data);
  }

  newDatabase(data: boolean) {
    this._newDatabase.next(data);
  }


  addNormalizedtoDatabase(data: iNormalizedDataLISTDTO) {
    this._addNormalizedToDataBase.next(data);
  }

  refreshDatabaseToShow(data: string[]) {
    this._databases_ToShow_Message.next(data);
  }

  selecteDatabaseToInvestigate(data: outDNADatabaseDTO) {
    this._databases_Selected_Message.next(data)
  }
  selecteNormalizedDataToInvestigatee(data: iNormalizedDataLISTDTO) {
    this._normalizedData_Selected_Message.next(data)
  }

  addedCategory(data: iCategoryOut) {
    this._category_added_Message.next(data)
  }



}


