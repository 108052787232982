import { numbers } from "@material/snackbar";
import { max } from "d3";

export interface InvestigationResult {
  k: number;
  gini: number;
  categories: CategoryResult[];
}

export interface CategoryResult {
  name: string,
  average: number;
  variance: number;
  max: number;
  max_prob: number,
  max_similarity: number,
  min: number;
  min_prob: number,
  min_similarity: number,
  results: SingleResult[];
  prob: number,
}
export interface SingleResult {
  id : string,
  code: string,
  distance: number,
  similarity : number,

  is_k: boolean,
  is_k_sim : boolean
}


export function fillInvestigationResult(result: InvestigationResult) {


  let gini: number = 0;
  if (result.categories) {

    result.categories.forEach(x => x.results.sort((a, b) => (a.distance > b.distance) ? 1 : -1))
    let denominator: number = 0;
    let denominator_prob: number = 0;
    let prob_sum: number = 0;
    let probs: number[] = []
    let maxDist: number = 0;

    gini = 0;
    let N = result.categories.length;
    result.categories.forEach(x => {
      x.results.forEach(y => {
        denominator = denominator + Math.exp(y.distance)
        maxDist = y.distance > maxDist ? y.distance : maxDist;
      });
    })
    result.categories.forEach(x => {

      let max_similarity = 0;
      let min_similarity = Number.MAX_VALUE;
      result.k < x.results.length ? x.results[result.k - 1].is_k = true : x.results[x.results.length - 1].is_k = true
      x.results.forEach(y => {

        y.similarity = Math.pow(maxDist - y.distance, 3);
        max_similarity = y.similarity > max_similarity ? y.similarity : max_similarity;
        min_similarity = y.similarity < min_similarity ? y.similarity : min_similarity;
      });

      x.max_similarity = max_similarity
      x.min_similarity = min_similarity


    })
    result.categories.forEach(x => x.results.sort((a, b) => (a.similarity > b.similarity) ? -1 : 1))
    result.categories.forEach(x => {
      // result.k < x.results.length ? x.results[result.k - 1].is_k_sim = true : x.results[x.results.length - 1].is_k_sim = true
      if (result.k < x.results.length) {
        x.results[result.k - 1].is_k_sim = true
        denominator_prob = denominator_prob + x.results[result.k - 1].similarity
      } else {
        x.results[x.results.length - 1].is_k_sim = true
        denominator_prob = denominator_prob + x.results[x.results.length - 1].similarity
      }
    })
    result.categories.forEach(x => {
      // result.k < x.results.length ? x.results[result.k - 1].is_k_sim = true : x.results[x.results.length - 1].is_k_sim = true
      if (result.k < x.results.length) {
        x.prob = Math.round((x.results[result.k - 1].similarity / denominator_prob) * 10000)/100
      } else {
        x.prob =   Math.round((x.results[x.results.length - 1].similarity / denominator_prob)*10000)/100
      }
      probs.push(x.prob)
    })



    probs.forEach(i => {
      let temp: number = 0;
      probs.forEach(j => {
        temp = temp + Math.abs(i - j) / (2 * N * denominator_prob);
      })
      gini = gini + temp;
    })
    result.gini = gini;
  }


}
