import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { inDNADatabaseDTO } from '../../../../s3qr-DNADTO/src/dto/DNADatabaseDTO';
import { iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }


  public async postDatabase(id: inDNADatabaseDTO): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.post(environment.main_url + environment.backend_dna_dnadatabases,id).toPromise();

    //
  }

  public async getDatabases(): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_dnadatabases).toPromise();

    //
  }



  public async postNormalizedData(id: string, towrite: iNormalizedDataLISTDTO[]): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.post(environment.main_url + environment.backend_dna_dnadatabase +"/" + id + environment.backend_dna_normalizedDatas, towrite).toPromise();

    //
  }

  public async getNormalizedDataList(id: string): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.get("http://127.0.0.1:5022/dnadatabase/" + id + "/NormalizedDatas/list").toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_dnadatabase + "/" + id + environment.backend_dna_NormalizedDatas_list).toPromise();

    //
  }

  public async getNormalizedDataFull(id: string): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_dnadatabase + "/" + id + environment.backend_dna_NormalizedDatas_full).toPromise();

    //
  }



}
