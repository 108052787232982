


<table mat-table [dataSource]="categories" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

<!-- Position Column -->

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Code </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>
    <!-- Name Column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Type </th>
    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
  </ng-container>
  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef> Creation Date </th>
    <td mat-cell *matCellDef="let element"> {{element.creationDate | date: "dd/MM/yyyy" }} </td>
  </ng-container>

  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef> Author </th>
    <td mat-cell *matCellDef="let element"> {{element.author}} </td>
  </ng-container>

<!-- Add Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef> Normalize </th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="edit(element)" color="warn" >
        <mat-icon>edit</mat-icon></button>

    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


</table>
