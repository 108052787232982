import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { MyWebToken } from '../models/webtoken';
import * as papa from "papaparse";
import * as d3 from 'd3-selection';
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import { IntecomService } from '../providers/intecom.service';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalType } from '../showimage/showimage.component';

import { Logger, LogLevel } from 'ask-logger';
import { iNormalizedDataElementDTO } from 's3qr-dnadto/src/dto/NormalizedDataElementDTO';
import { iRawDataDTOOUT, iRawDataFullDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { RawDataService } from '../providers/RawData.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { iNormalizedDataDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { RawDataAssembler } from 's3qr-dnadto/src/assembler/RawDataAssembler';
import { Subscription } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { logged } from '../support/logged';
import { KcConnectService } from '../providers/kc-connect.service';
import { NormalizedDataSaveFormComponent } from '../normalizedDataSaveForm/normalizedDataSaveForm.component';
import { runInThisContext } from 'vm';

const LOGGER = Logger.getLogger('DnagraphformComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-dnagraph',
  templateUrl: './dnagraph.component.html',
  styleUrls: ['./dnagraph.component.css']
})
export class DnagraphComponent extends logged implements OnInit {

  static FilterHeight: string = "Filter Heigth:";
  static FilterGauss: string = "Filter Height*size^p:";

  modalType: ModalType = ModalType.INFO;
  c3chart: any;
  width: number;
  height: number;
  margin: any;
  x: any;
  y: any;
  svg: any;
  g: any;
  fontsize: string;
  isThereAGraph: boolean = false;
  normalizedData: iNormalizedDataDTO;
  data: iRawDataFullDTOOUT;
  labelX: number[] = [];
  subscription_RawData: Subscription;
  subscription_NormalizedData: Subscription;
  nameVerticalFilter: string = DnagraphComponent.FilterHeight;

  max_value: number = 0;
  max_couple: number[] = [0, 0]

  constructor(public dialog: MatDialog, private rawDataService: RawDataService,
    private _snackBar: MatSnackBar,
    private intercom: IntecomService,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService) {

    super(keycloakService, comunication);

    this.isThereAGraph = false;

    if (window.innerWidth < 600) {
      this.fontsize = "25px";
      this.margin = { top: 10, right: 10, bottom: 60, left: 90 };
    } else {
      this.fontsize = "10px";
      this.margin = { top: 10, right: 10, bottom: 40, left: 40 };
    }
    this.width = 900 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;




  }

  valuechange(newValue) {
    this.normalizedData.filterH = 0;
    this.normalizedData.exp = newValue;
    // console.log(newValue)

    LOGGER.info("===========EXP======================");
    LOGGER.info("[p] Event -> ", this.normalizedData.exp);
    LOGGER.info("[p] max Couple Multiply -> ", this.max_couple);
    LOGGER.info("[p] max Max Value couple multyply ^1 -> ", +this.max_value);
    LOGGER.info("[p] size^p -> ", Math.pow(this.max_couple[0], this.normalizedData.exp));
    this.normalizedData.filterH = 0;
    this.normalizedData.maxHeight = this.max_couple[1] * Math.pow(+this.max_couple[0], this.normalizedData.exp);
    LOGGER.info("[p] maxHeight -> ", this.normalizedData.maxHeight);
    this.nameVerticalFilter = DnagraphComponent.FilterGauss;
    this.showgraph()


  }

  ngOnInit() {
    LOGGER.info("===========new version======================");
    LOGGER.info("TOKEN1 ", this.token)
    this.subscription_NormalizedData = this.intercom.normalizedDataFormGraph$.subscribe((message: iNormalizedDataDTO) => {
      try {
        LOGGER.info("GRAFICO|!!!!!! ", message)
        const pippo = message.points
        LOGGER.info(pippo)


        this.normalizedData = message

        this.initSvg();
        this.initAxis_fromPoints();
        this.drawAxis();
        this.drawBars(this.normalizedData.points);
      } catch (e) {
        LOGGER.error(e)
      }

    }, err => {
      LOGGER.error("sub 2 err", err);
    })

    this.subscription_RawData = this.intercom.dnaAnalysisForm$.subscribe((message: iRawDataDTOOUT) => {

      LOGGER.info("Message arrived :", message)
      const rawData: iRawDataDTOOUT = message;
      this.rawDataService.getRawDataById(rawData.id).then(async (result: iRawDataFullDTOOUT) => {
        this.data = result;
        LOGGER.info("RAW : ", result)
        this.data.rawdatas.forEach(x => {
          if (+x.size * +x.height > this.max_value) {
          this.max_value = +x.size * +x.height
          this.max_couple = [+x.size, +x.height]
        }
      })
      LOGGER.info("RAW : ", this.max_value)
      const token_value = await this.keycloakService.getToken();
      this.token = await this.comunication.loggedIn_data(token_value);
      LOGGER.info("TOKEN: ", this.token)
      this.normalizedData = RawDataAssembler.fromRawDataToNormalizedData(result, this.token.user.name, "");
      this.normalizedData.heightFilterNoise = true;
      LOGGER.info("RAW : ", result)
      LOGGER.info("NORMALIZED : ", this.normalizedData)
      this.normalizedData.maxHeight = 100;
      this.nameVerticalFilter = DnagraphComponent.FilterHeight;
      if (this.normalizedData.points) {
        this.initSvg();
        this.initAxis_fromPoints();
        this.drawAxis();
        this.drawBars(this.normalizedData.points);
      } else {
        this._snackBar.open("Error creating graph", "Ok");
        LOGGER.error(this.normalizedData)
      }

    }).catch(e => {
      this._snackBar.open("Error retrieving data from server 2", "Ok");
      LOGGER.error(e)
    })
  })




}

ngOnDestroy() {

}

elabore() {
  LOGGER.info("[elabore] height_filter_noise-> ", this.normalizedData.heightFilterNoise);
  let dialogConfig = new MatDialogConfig();
  dialogConfig.autoFocus = true;
  dialogConfig.minWidth = 800;


  dialogConfig.data = this.normalizedData
  const dialogRef2 = this.dialog.open(NormalizedDataSaveFormComponent, dialogConfig);
  dialogRef2.afterClosed().subscribe(result => {
    if (result) {

      this._snackBar.open("Saved Successfully", "Ok");
    } else {
      this._snackBar.open("Something gone wrong", "Ok");
    }

  });
}


radioChange($event) {
  LOGGER.info("[radioChange] Event -> ", $event);
  this.normalizedData.filterH = 0;
  this.normalizedData.heightFilterNoise = JSON.parse($event.value);
  if (JSON.parse($event.value)) {
    this.normalizedData.maxHeight = 100;
    this.nameVerticalFilter = DnagraphComponent.FilterHeight;
  } else {
    this.normalizedData.maxHeight = this.normalizedData.maxH * Math.pow(+this.normalizedData.maxS, this.normalizedData.exp);
    this.nameVerticalFilter = DnagraphComponent.FilterGauss;
  }
  LOGGER.info("[radioChange] this.maxHeight -->", this.normalizedData.maxHeight)
  this.showgraph()
}



showgraph() {

  // this.clearGraph();
  // LOGGER.info("[showgraph]")
  this.normalizedData.points = [];
  this.normalizedData.normalizedDataElements = [];
  // LOGGER.info("NORMALIZED 2 : ", this.normalizedData)
  for (let element of this.data.rawdatas) {
    // this.normalizedData.data.forEach((element) => {

    // console.log("filter:", this.filterH, " element", element.size)
    if (this.normalizedData.filterS < +element.size) {

      let _y: number = Math.floor((+element.height / this.normalizedData.maxH) * 100);
      //console.log(this.normalizedData.heightFilterNoise);
      // LOGGER.info("[showgraph] this.heightFilterNoise: " + this.normalizedData.heightFilterNoise);
      if (this.normalizedData.heightFilterNoise) {

        if (this.normalizedData.filterH < _y) {
          this.normalizedData.points.push({ x: +element.size, y: _y })
          let n_e: iNormalizedDataElementDTO = element;
          n_e.n_size = +element.size;
          n_e.n_height = +_y;
          this.normalizedData.normalizedDataElements.push(n_e)

        } else {
          // this.normalizedData.points.push({ x: +element.size, y: 0 })
          // let n_e: iNormalizedDataStructure = element;
          // n_e.n_size = "" + element.size;
          // n_e.n_height = "" + 0;
          // this.normalizedData.normalizedData.push(n_e)

        }
      } else {

        let temp_y = +element.height * Math.pow(+element.size, this.normalizedData.exp);
        // LOGGER.info("Temp_y", temp_y)
        // LOGGER.info("this.normalizedData.filterH", this.normalizedData.filterH)
        if (this.normalizedData.filterH < temp_y) {

          LOGGER.info("===========VALUE inserted======================");
          LOGGER.info("[p] element.height -> ", element.height);
          LOGGER.info("[p] element.size -> ", element.size);
          LOGGER.info("[p] ELEVAMENTO A POTENZA [" + this.normalizedData.exp + "] -> ", Math.pow(+element.size, this.normalizedData.exp));
          LOGGER.info("[p] size^p -> ", temp_y);

          this.normalizedData.points.push({ x: +element.size, y: _y })
          let n_e: iNormalizedDataElementDTO = element;
          n_e.n_size = + element.size;
          n_e.n_height = + _y;
          this.normalizedData.normalizedDataElements.push(n_e)
          this.initAxis_fromPoints();
          // } else {
          // this.normalizedData.points.push({ x: +element.size, y: 0 })
          // let n_e: iNormalizedDataStructure = element;
          // n_e.n_size = "" + element.size;
          // n_e.n_height = "" + 0;
          // this.normalizedData.normalizedData.push(n_e)

        }
      }

    }
    // LOGGER.info("=========================================")
    // LOGGER.info(this.normalizedData)
    // LOGGER.info("=========================================")
    this.intercom.refreshNormalizedData(this.normalizedData)
  }

  // console.log("->", this.points)
  this.initSvg();
  this.initAxis_fromPoints();
  this.drawAxis();
  this.drawBars(this.normalizedData.points);
}

clearGraph() {
  this.normalizedData.points = [];
  this.isThereAGraph = false;
  this.svg.remove();

}


initSvg() {

  // if (!this.isThereAGraph) {
  d3.selectAll("g > *").remove()

  if (!this.svg) {
    this.svg = d3.select("#barChart3")
      .append("svg")
      .attr("width", '100%')
      .attr("height", '100%')
      .attr('viewBox', '0 0 900 500');
  }

  this.g = this.svg.append("g")
    .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  // this.isThereAGraph = true;
  // } else {
  //   d3.selectAll("g > *").remove()
  //   this.g = this.svg.append("g")
  //     .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  // }



}
initAxis(x: number[], y: number[]) {
  // this.x = d3Scale.scaleBand().rangeRound([0, this.width]).padding(0.1);
  this.x = d3Scale.scaleLinear().domain([0, d3Array.max(x)]).range([0, this.width]);
  this.y = d3Scale.scaleLinear().domain([d3Array.max(y), 0]).range([0, this.height]);
  // this.y = d3Scale.scaleLinear().rangeRound([this.height, 0]);
  // this.x.domain(y);
  // this.y.domain([0, d3Array.max(y)]);
}

initAxis_fromPoints() {
  let x1: number[] = [];
  let y1: number[] = [];
  // console.log("Ci entro: ", this.normalizedData.points)
  // this.normalizedData.points.forEach(point => {
  //   console.log(point)
  // })

  for (let i = 0; i < this.normalizedData.points.length; i++) {
    x1.push(+this.normalizedData.points[i].x);
    y1.push(+this.normalizedData.points[i].y);

  }
  // console.log("aaa : " + JSON.stringify(x1));
  this.x = d3Scale
    .scaleLinear()
    .domain([0, this.normalizedData.maxS])
    .range([0, this.width]);
  this.y = d3Scale
    .scaleLinear()
    .domain([100, 0])
    .range([0, this.height]);

  // this.x = d3Scale
  //   .scaleLinear()
  //   .domain([0, 500])
  //   .range([0, 500]);
  // this.y = d3Scale
  //   .scaleLinear()
  //   .domain([100, 0])
  //   .range([0, 100]);

  // console.log("------x-------------------------");
  // console.log(this.x);
  // console.log("-------------------------------------");
}

drawAxis() {

  this.g.append("g")
    .attr("class", "axis axis--x")
    .style("font-size", this.fontsize)
    .attr("transform", "translate(0," + this.height + ")")
    .call(d3Axis.axisBottom(this.x));
  this.g.append("g")
    .style("font-size", this.fontsize)
    .attr("class", "axis axis--y")
    .call(d3Axis.axisLeft(this.y))
    .append("text")
    .attr("class", "axis-title")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", "0.71em")
    .attr("text-anchor", "end")
    .text("Frequency");
}

drawBars(points: any[]) {
  this.g.selectAll(".bar")
    .data(points)
    .enter().append("rect")
    .style("fill", "green")
    .attr("class", "bar")
    .attr("x", (d) =>
      this.x(d.x)
    )
    .attr("y", (d) => this.y(d.y))
    .attr("width", (d) => "1")
    .attr("height", (d) => this.height - this.y(d.y));
}




}

