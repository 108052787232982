import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from "d3-scale";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import * as d from 'd3';
import * as d3pdf from 'd3-save-pdf';
// import * as d3ToPng from 'd3-svg-to-png';
import { IntecomService } from '../providers/intecom.service';
import * as svgx from 'save-svg-as-png';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import autoTable from 'jspdf-autotable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { iNormalizedDataDTO, iNormalizedDataLISTDTO } from 's3qr-dnadto/src/dto/NormalizedDataDTO';
import { jsPDF } from 'jspdf';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { Logger, LogLevel } from 'ask-logger';
import { Point } from '../models/Points';
import { CategoryResult, InvestigationResult } from '../models/InvestigationResult';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CLS_RTE_IMAGE } from '@syncfusion/ej2-angular-richtexteditor';
import { CellDef } from '@angular/cdk/table';
const LOGGER = Logger.getLogger('InvestigationGraphComponent')
LOGGER.set_level(LogLevel.DEBUG)
const EXAMPLE: number = 20;
@Component({
  selector: 'app-InvestigationGraph',
  templateUrl: './InvestigationGraph.component.html',
  styleUrls: ['./InvestigationGraph.component.scss']
})

export class InvestigationGraphComponent implements OnInit {

  c3chart: any;
  fontsize: string;
  margin: any;
  width: number;
  height: number;
  g: any;
  svg: any;
  x: any;
  x_n: any;
  y: any;
  points: Point[] = []
  gini: number = 0;
  subscription: Subscription;
  investigation_result: InvestigationResult;
  max_y: number = 0;
  bar_length: number = 0;
  @ViewChild('barChart3', { static: false }) pdfTable: ElementRef;
  logo: string;
  cat_results: CategoryResult[] = []
  constructor(private normalizedDataService: NormalizedDataService,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private intercom: IntecomService,

  ) {



    if (window.innerWidth < 600) {
      this.fontsize = "25px";
      this.margin = { top: 10, right: 10, bottom: 60, left: 90 };
    } else {
      this.fontsize = "10px";
      this.margin = { top: 10, right: 10, bottom: 20, left: 40 };
    }
    this.width = 900 - this.margin.left - this.margin.right;
    this.height = 400 - this.margin.top - this.margin.bottom;

  }

  ngOnInit() {
    this.subscription = this.intercom.investigationResultArrived$.subscribe((result: InvestigationResult) => {
      if (result) {
        // for pdf export
        this.cat_results = Object.assign([], result.categories);
        this.cat_results.sort((a, b) => (a.prob > b.prob) ? -1 : 1)

        this.gini = result.gini
        LOGGER.info(result)
        this.investigation_result = result;
        // this.points.push({ x: 0, y: 0, max: 0, min: 0, label: "" });
        for (let i = 0; i < this.investigation_result.categories.length; i++) {

          const y_sim: number = this.investigation_result.categories[i].results.find(x => x.is_k_sim).similarity;
          const max_sim: number = this.investigation_result.categories[i].max_similarity;
          const min_sim: number = this.investigation_result.categories[i].min_similarity;
          this.max_y = max_sim > this.max_y ? max_sim : this.max_y;
          this.points.push({ x: i, y: y_sim, max: max_sim, min: min_sim - y_sim, label: this.investigation_result.categories[i].name + " " + this.investigation_result.categories[i].prob + "%", prob: this.investigation_result.categories[i].prob });

        }
        // this.points.push({ x: 0, y: 0, max: 0, min: 0, label: " " });
        this.max_y = this.max_y + (this.max_y * 0.1)



        this.initSvg();
        this.initAxis_fromPoints();
        this.drawAxis();
        this.drawBars();
      }
    })
    this.http.get("assets/img/logos3.png", { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          let base64data = reader.result;
          console.log("--33-->" + base64data);
          this.logo = base64data + "";
        }
        reader.readAsDataURL(res);
      });



  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();

  }

  save() {

  }

  exportPDF() {
    const d3ToPng = require('d3-svg-to-png');
    svgx.svgAsPngUri(d3.select('svg').node(), {}, (uri) => {
      console.log('png base 64 encoded', uri);

      const doc = new jsPDF({ orientation: 'l', unit: "pt" });
      doc.text("Gini Index :" + this.gini, 50, 50);
      doc.addImage(this.logo, 'PNG', 700, 10, 102, 30);
      doc.addImage(uri, 'PNG', 10, 100, this.width, this.height + 50);

      let counter = 0;

      let towrite: string[][] = [];

      doc.addPage("a4", "p");


      doc.addImage(this.logo, 'PNG', 470, 10, 102, 30)
      doc.setFontSize(15)
      for (let i = 0; i < this.cat_results.length; i++) {
        let finalY = (doc as any).lastAutoTable.finalY || 100;
        if (finalY > 300) {
          finalY = 100;
          doc.addPage("a4", "p");
          doc.addImage(this.logo, 'PNG', 470, 10, 102, 30)
        }

        doc.text(this.cat_results[i].name.toUpperCase() + ": " + this.cat_results[i].prob + "%", 50, finalY + 50);

        autoTable(doc, {
          startY: finalY + 70,
          body: [
            ["Average :", Math.floor(this.cat_results[i].average*100)/100],
            ["Variance :", Math.floor(this.cat_results[i].variance * 100) / 100],
            ["Max Distance^p :", Math.floor(this.cat_results[i].max * 100) / 100],
            ["Min Distance^p :", Math.floor(this.cat_results[i].min * 100) / 100],
            ["Possibility :", this.cat_results[i].prob + "%"],
            ["Max Similarity :", Math.floor(this.cat_results[i].max_similarity * 100) / 100],
            ["Min Similarity :", Math.floor(this.cat_results[i].min_similarity * 100) / 100]
          ],
        })
        let table = [];

        for (let a = 0; a < this.cat_results[i].results.length; a++) {

          table[a]= [this.cat_results[i].results[a].code, this.cat_results[i].results[a].distance, this.cat_results[i].results[a].similarity]

        }
        // table[a].push([this.cat_results[i].results[a].code, this.cat_results[i].results[a].distance] ])
        finalY = (doc as any).lastAutoTable.finalY
        autoTable(doc, {
          startY: finalY + 20,
          head: [['Code', 'Distance', 'Similarity']],
          body: table,
        })
        counter++;
      }








      doc.save("a4.pdf");

    });





  }

  initSvg() {

    // if (!this.isThereAGraph) {
    d3.selectAll("g > *").remove()

    if (!this.svg) {
      this.svg = d3.select("#barChart3")
        .append("svg")
        .attr("width", '100%')
        .attr("height", '100%')
        .attr('viewBox', '0 0 980 500');
    }

    this.g = this.svg.append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    // this.isThereAGraph = true;
    // } else {
    //   d3.selectAll("g > *").remove()
    //   this.g = this.svg.append("g")
    //     .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    // }



  }
  initAxis(x: number[], y: number[]) {
    // this.x = d3Scale.scaleBand().rangeRound([0, this.width]).padding(0.1);
    this.x = d3Scale.scaleLinear().domain([0, d3Array.max(x)]).range([0, this.width]);
    this.y = d3Scale.scaleLinear().domain([this.max_y, 0]).range([0, this.height]);


    LOGGER.info("this.x", x)
    LOGGER.info("this.y", y)
    // this.y = d3Scale.scaleLinear().rangeRound([this.height, 0]);
    // this.x.domain(y);
    // this.y.domain([0, d3Array.max(y)]);
  }

  initAxis_fromPoints() {
    let x1: number[] = [];
    let y1: number[] = [];
    console.log("Ci entro: ", this.points)
    // this.normalizedData.points.forEach(point => {
    //   console.log(point)
    // })

    for (let i = 0; i < this.points.length; i++) {
      x1.push(+this.points[i].x);
      y1.push(+this.points[i].y);

    }
    // console.log("aaa : " + JSON.stringify(x1));
    this.x = d3Scale
      .scaleBand()
      .domain(this.points.map(d => d.label))
      .range([0, this.width])
      .paddingInner(0.1)
      .paddingOuter(0.1);


    this.y = d3Scale
      .scaleLinear()
      .domain([this.max_y, 0])
      .range([0, this.height]);

    this.x_n = d3Scale
      .scaleLinear()
      .domain([this.points.length, 0])
      .range([0, this.width]);
    this.bar_length = this.points.length > 0 ? this.x(this.points[1].label) - this.x(this.points[0].label) : 20

    LOGGER.info("======================================>", this.bar_length)
  }




  // gridlines in y axis function
  make_x_gridlines() {
    return d.axisLeft(this.y)
      .ticks(10)

  }




  drawAxis() {

    // this.g.append("g")
    //   .attr("class", "axis axis--x")
    //   .style("font-size", this.fontsize)

    //   .attr("transform", "translate(0," + this.height + ")")
    //   .call(d3Axis.axisBottom(this.x)
    //    );

    this.g.append("g")
      .attr("class", "axis axis--x")
      .style("font-size", this.fontsize)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3Axis.axisBottom(this.x))
      .selectAll("text")
      .attr("transform", "translate(-10,10)rotate(-45)")
      .style("text-anchor", "end")


    this.g.append("g")
      .style("font-size", this.fontsize)
      .attr("class", "axis axis--y")
      .call(d3Axis.axisLeft(this.y))


    // .call(this.make_x_gridlines()
    //   .tickSize(1)
    //   .tickValues([])
    // )
    // .append("text")
    // .attr("class", "axis-title")
    // .attr("transform", "rotate(-90)")
    // .attr("y", 6)
    // .attr("dy", "0.71em")
    // .attr("text-anchor", "end")
    // .text("Frequency");

    // this.g.append("g")
    //   .attr("class", "grid")
    //   .attr("transform", "translate(0," + this.height + ")")
    //   .select(".domain").remove()
    //   .call(this.make_x_gridlines()
    //     .tickSize(-this.height)
    //     .tickFormat(this.x)
    // )

    // add the Y gridlines
    this.g.append("g")
      .attr("class", "grid")
      .attr("opacity", 0.3)
      .call(this.make_x_gridlines()
        .tickSize(-this.width)
        .tickFormat((d) => '')
      ).select(".domain").remove()
  }



  drawBars() {
    this.g.selectAll(".bar")
      .data(this.points)

      .enter()
      .append("rect")
      .attr("x", (d) => this.x(d.label))
      .attr("y", (d) => this.y(d.max))
      .attr("width", this.bar_length)
      .attr("height", (d) => {

        console.log("Height : " + this.height + " | y: " + d.y + " | this.y : " + this.y(d.y) + " | d max:" + this.y(d.max))
        return this.y(Math.abs(d.y)) - this.y(d.max)
      })
      .attr("fill", (d) => {

        return d.x % 2 > 0 ? "red" : "teal"
      })
      .attr("opacity", 0.3)



    this.g.selectAll(".bar")
      .data(this.points)
      .enter()
      .append("rect")
      .attr("x", (d) => this.x(d.label))

      .attr("y", (d) => {

        return this.y(d.y)
      })
      .attr("width", this.bar_length)
      .attr("height", (d) => {

        // LOGGER.info("D.Y (" + d.y + "): ", this.y(d.y))
        // LOGGER.info("D.MIN  (" + d.y + "): ", this.y(d.min))
        // LOGGER.info("D.MIN-Y (" + d.y + "): ", this.y(d.y) - this.y(d.min))
        return this.height - this.y(Math.abs(d.min))
        //return this.height
      })
      .attr("fill", (d) => {

        return d.x % 2 > 0 ? "red" : "teal"
      })
      .attr("opacity", 0.3)

    // barra della media

    this.g.selectAll(".bar")
      .data(this.points)
      .enter()
      .append("line")
      .attr("x1", (d) => this.x(d.label))
      .attr("y1", (d) => this.y(d.y))
      .attr("x2", (d) => this.x(d.label) + this.bar_length)
      .attr("y2", (d) => this.y(d.y))
      .attr("stroke", (d) => {

        return d.x % 2 > 0 ? "red" : "teal"
      })
      .attr("stroke-width", (d) => {
        LOGGER.info("d.x", d.x)
        LOGGER.info("this.points.length - 1", this.points.length - 1)
        return d.label == " " ? 0 : 2
      });



  }


}
