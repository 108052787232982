<table mat-table [dataSource]="rawData"  matSort class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

<!-- Position Column -->

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by code" > Code </th>
    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
  </ng-container>
    <!-- Name Column -->
  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Author"> Author </th>
    <td mat-cell *matCellDef="let element"> {{element.author}} </td>
  </ng-container>
  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date"> Creation Date </th>
    <td mat-cell *matCellDef="let element"> {{element.creationDate | date: "HH:mm dd/MM/yyyy" }} </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Category"> Category </th>
    <td mat-cell *matCellDef="let element"> {{element.category}} </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="rawdatas_number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Number of normalized Data"> N° normalized </th>
    <td mat-cell *matCellDef="let element"> {{element.normalizedDatas_number}} </td>
  </ng-container>

<!-- Add Column -->
  <ng-container matColumnDef="graph">
    <th mat-header-cell *matHeaderCellDef> Normalize </th>
    <td mat-cell *matCellDef="let element">
      <button mat-mini-fab (click)="graph(element)" color="warn" >
        <mat-icon> bar_chart</mat-icon></button>

    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


</table>
  <mat-paginator [pageSizeOptions]="[10, 20, 40]"
                 showFirstLastButtons
                 >
