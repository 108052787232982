import { Component, OnInit } from '@angular/core';
import { Logger, LogLevel } from 'ask-logger';
import { NormalizedDataService } from '../providers/NormalizedData.service';
import { IntecomService } from '../providers/intecom.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
import { RawDataService } from '../providers/RawData.service';
const LOGGER = Logger.getLogger('RawDataFinder2Component')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-rawDataFinder2',
  templateUrl: './rawDataFinder2.component.html',
  styleUrls: ['./rawDataFinder2.component.css']
})
export class RawDataFinder2Component implements OnInit {

  myControl = new FormControl();
  data: iRawDataDTOOUT[] = [];
  options: string[] = [];
  alloptions: string[] = [];
  selected: string;
  _showgraph: boolean = false;
  filteredOptions: Observable<string[]>;

  constructor(private rawDataService: RawDataService, private intercom: IntecomService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    //  FIL DATA WITH DATABASES
    this.rawDataService.getAllRawData().then((response) => {

      if (response) {
        this.data = response;

        LOGGER.info(this.data)
        this.data.forEach(x => this.alloptions.push(x.id +"#" + x.code + "@" + x.author + ":" + x.category));
      }
    }).catch((error) => {
      this._snackBar.open("Error retrinving data", "Ok");
      LOGGER.info(error)
    })

  }

  change(event) {
    LOGGER.info("valore da filtrare ->" + event)
    const filterValue = event.toLowerCase();
    this.options = this.alloptions.filter(x => x.toLowerCase().includes(filterValue))

  }


  go() {
    LOGGER.info(this.options)
    const toSend: string[] = [];
    if (this.options) {
      this.options.forEach(x => {
        if (x.split("#").length > 0) {
          toSend.push(x.split("#")[0]);
        }
      })
    }
    LOGGER.info(toSend)
    this.intercom.refreshRawDataToShow(toSend)
  }

  newDb() {
    this.intercom.newDatabase(true);

  }

}
