<header>
  <mat-card class="distanza titolo">
    <mat-card-title>
      <div class="row">
        <div class="column_center">
          <img class="logo3" src="assets/img/logos3.png" />
        </div>
        <div class="column_sx">{{ env.title }}</div>
        <div class="column_dx">
          <button mat-raised-button class="distanza2" (click)="logout()">
            LOGOUT
          </button>
          <button mat-raised-button class="distanza4" (click)="profile()">
            MY PROFILE
          </button>
        </div>
      </div>
    </mat-card-title>
    <mat-card-subtitle class="subtitle">
      Welcome : {{ token.user.name }}, you have been logged in!
    </mat-card-subtitle>
  </mat-card>
</header>

<!-- <mat-card class="distanza">
  <mat-card-subtitle> TOKEN FROM KEYCLOAK </mat-card-subtitle>
  <mat-card-content>
    <pre class="scritta">{{ token | json }}</pre>
  </mat-card-content>
</mat-card> -->
<div id="comp-render" *ngIf="display">
  <mat-card class="distanza coloremenu">
    <mat-card-content>
      <div class="row">
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToOpenLots()"
          >
          <mat-icon>star_border</mat-icon>

            PENDING ANALYSIS
          </button>
        </div>
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToArchive()"
          >
          <mat-icon>assignment</mat-icon>
            ARCHIVE ANALYSIS
          </button>
        </div>
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToDNA()"
          >
            <mat-icon> import_export</mat-icon>
            IMPORT DATA
          </button>
        </div>
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToRaw()"
          >
            <mat-icon>note_add</mat-icon>
            NORMALIZE DATA
          </button>
        </div>
        <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToNorm()"
          >
            <mat-icon>list</mat-icon>
            NORMALIZED DATA
          </button>
        </div>
        <!-- <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToPatogeni()"
          >
            PATOGENI
          </button>
        </div> -->
      </div>

      <div class="row">
        <div class="menu_all2">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToInvestigate()"
          >
          <mat-icon> insert_chart_outlined</mat-icon>

            CLASSIFICATION
          </button>
        </div>

        <div class="menu_all2">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToCategoryList()"
          >
              <mat-icon>note_add</mat-icon>
            CATEGORY
          </button>
        </div>
        <div class="menu_all2">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToDatabase()"
          >
            <mat-icon>note_add</mat-icon>
            DATABASE
          </button>
        </div>
        <div class="menu_all2">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToDatabaseList()"
          >
            <mat-icon>list</mat-icon>
            DATABASE
          </button>
        </div>
         <div class="menu_all2">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToInfo()"
          >
          <mat-icon>info</mat-icon>
            INFO
          </button>
        </div>
        <!-- <div class="menu_all">
          <button
            mat-stroked-button
            color="warn"
            class="distanza2 colorebottonimenu"
            (click)="goToPatogeni()"
          >
            PATOGENI
          </button>
        </div> -->
      </div>
    </mat-card-content>
  </mat-card>

  <div id="comp-render" *ngIf="_opnelots">
    <app-lotview [token]="token"></app-lotview>
  </div>
</div>
<div id="comp-render" *ngIf="_archive">
  <app-lotcompletedview [token]="token"></app-lotcompletedview>
</div>
<div id="comp-render" *ngIf="_dna">
  <app-analysis-g></app-analysis-g>
</div>
<div id="comp-render" *ngIf="_patogeni">
  <app-patogeni-graph></app-patogeni-graph>
</div>
<div id="comp-render" *ngIf="_raw">
  <app-rawDataMain></app-rawDataMain>
</div>
<div id="comp-render" *ngIf="_norm">
  <app-normalizedDataMain></app-normalizedDataMain>
</div>
<div id="comp-render" *ngIf="_database">
  <app-databaseAddMain></app-databaseAddMain>
</div>
<div id="comp-render" *ngIf="_databaselist">
  <app-databaseMain></app-databaseMain>
</div>
<div id="comp-render" *ngIf="_investigation">
  <app-InvestigationMain></app-InvestigationMain>
</div>
<div id="comp-render" *ngIf="_categoryList">
  <app-categoryMain></app-categoryMain>>
</div>
<div id="comp-render" *ngIf="_info">
  <app-info></app-info>
</div>

<footer>

  <mat-card class="distanza titolo">
    <mat-card-content>
      <p>{{ env.bottom }}</p>
    </mat-card-content>
  </mat-card>
</footer>
