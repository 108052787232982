import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

import { iRawDataElementDTO } from 's3qr-dnadto/src/dto/RawDataElementDTO';
import { iRawDataDTOIN } from 's3qr-dnadto/src/dto/RawDataDTO';


@Injectable({
  providedIn: 'root'
})
export class RawDataService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }


  public async postRawData(towrite : iRawDataDTOIN): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.post("http://127.0.0.1:5022/rawDatas",towrite).toPromise();

    return this.http.post(environment.main_url + environment.backend_dna_rawDatas, towrite).toPromise();

    //
  }

  public async postRawDataElements(id: string, towrite: iRawDataElementDTO[]): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.put("http://127.0.0.1:5022/rawData/" + id + "/rawDataElements", towrite).toPromise();
    return this.http.put(environment.main_url +  environment.backend_dna_rawData +"/" + id + environment.backend_dna_rawDataElements, towrite).toPromise();

    //
  }


  public async getAllRawData(): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.get("http://127.0.0.1:5022/rawDatas").toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_rawDatas).toPromise();

    //
  }

  public async getRawDataById(id : string ): Promise<any> {

    // return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();
    // return this.http.get("http://127.0.0.1:5022/rawData/" + id).toPromise();
    return this.http.get(environment.main_url + environment.backend_dna_rawData +"/" + id).toPromise();

    //
  }



}
