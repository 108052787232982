import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IntecomService } from '../providers/intecom.service';
import { Logger, LogLevel } from 'ask-logger';
import { iRawDataDTOOUT } from 's3qr-dnadto/src/dto/RawDataDTO';
const LOGGER = Logger.getLogger('RawDataMainComponent')
LOGGER.set_level(LogLevel.DEBUG)
@Component({
  selector: 'app-rawDataMain',
  templateUrl: './rawDataMain.component.html',
  styleUrls: ['./rawDataMain.component.css']
})
export class RawDataMainComponent implements OnInit {

  show_graph_sub: Subscription;
  show_graph: boolean = false
  selected: string;

  constructor(private intercom: IntecomService) { }

  ngOnInit() {

    this.show_graph_sub = this.intercom.dnaAnalysisForm$.subscribe((message: iRawDataDTOOUT) => {

      LOGGER.info("Message arrived :", message)
      if (message) {
        this.show_graph = true;
        this.selected = message.code + " " + message.author + ":" + message.category
      } else {
        this.show_graph = false;
      }


    })
  }

  back() {
    this.intercom.dnaAnalysisRefrehForm(null)
  }

}
