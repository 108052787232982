<div class="forminput">
  <mat-label>Upload/change the file of data (csv format) result of the analysis</mat-label><br />
  <br />

  <div class="row distancetop">
    <input
      type="file"
      (change)="getFileGrafico($event)"
      id="filesdna"
      #filesdna
    />
  </div>
