import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToParse } from '../models/ToParse';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ParserService {

  constructor(private http: HttpClient) { }


  public async sendDocument(file : string): Promise<any> {
    let tosend = new ToParse(file);
    return this.http.post(environment.main_url + environment.backend_parser, tosend ).toPromise();

  }
}
